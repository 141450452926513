import { useEffect, useState, useRef, DependencyList } from 'react';

const useFetch = (
  url: string,
  options?: Object,
  dependencyList: DependencyList = [],
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<any> | Object | any>([]);
  const [error, setError] = useState<any | null>(null);

  const cancelRequest = useRef<boolean>(false);

  const fetchData = async (
    controller: AbortController | any | undefined,
  ): Promise<void> => {
    const opt = { ...options, ...{ signal: controller.signal } };
    setIsLoading(true);
    try {
      const res = await fetch(url, opt);
      const jsonData = await res.json();
      if (cancelRequest.current) return;
      setData(jsonData);
      controller = null;
      setIsLoading(false);
    } catch (error: any) {
      if (cancelRequest.current) return;
      setError(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);
  return { isLoading, data, error };
};

export default useFetch;
