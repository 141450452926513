import React from 'react';
import { Checkbox } from '@mantine/core';
import { nanoid } from 'components/utils/utils';
import Img from 'components/image/Image';

import dummyImg from './assets/dummy.png';

type Props = {
  data: Array<string>;
  setTeasers: (s: string[]) => void;
  teasers: string[];
};

const TeaserCheckbox = ({ data, setTeasers, teasers }: Props) => {
  const handleOnChange = (id: string) => {
    if (!teasers.includes(id)) {
      teasers.push(id);
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {data.map((id: string) => {
        return (
          <div
            key={nanoid()}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '1rem',
              marginLeft: '1rem',
              backgroundColor: '#f1f1f1',
              width: '250px',
              padding: '1rem',
            }}
          >
            <Img
              width={'100%'}
              height={'100%'}
              src={`https://mab.partnerstudio.io/mab-static-www/teaser/thumbnail/${id}.webp`}
              alt={id}
              placeholderImg={dummyImg}
              errorImg={dummyImg}
            />
            <Checkbox
              onChange={() => {
                handleOnChange(id);
              }}
              value={id}
              label={id}
              mt={'md'}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TeaserCheckbox;
