import React, { useEffect } from 'react';
import { Overlay, Container, Title, Button, Text } from '@mantine/core';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useStyles } from './styles/Home.styles';

const Home = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (authState?.isAuthenticated) {
      history.push('/dashboard');
    }
  }, [authState?.isAuthenticated, history]);

  const handleClick = () => {
    history.push('/dashboard');
  };

  return (
    <div className={classes.hero}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container}>
        <Title className={classes.title}>MAB</Title>
        <Title className={classes.description}>The Contextual Bandit</Title>
        <Text className={classes.description} size="xl" mt="xl">
          – A solution for automatic optimization of KPIs for front teasers in
          Schibsted Partnerstudio
        </Text>

        <Button
          gradient={{ from: '#2980b9', to: '#3e99d6' }}
          variant="gradient"
          size="md"
          radius="sm"
          className={classes.control}
          onClick={() => {
            handleClick();
          }}
        >
          Sign-in with okta
        </Button>
      </Container>
    </div>
  );
};

export default React.memo(Home);
