import React, { useEffect, useState } from 'react';
import Table from './Table';
import Card from 'components/Card';
import { Group, Menu } from '@mantine/core';

import {
  Hide,
  Visible,
} from 'components/assets/icons/component_icons/visibility';
import variables from 'global.module.scss';

type Props = {
  data: Array<any>;
  headers: Array<object>;
  title?: string;
};

const TableStats = ({ data, headers, title }: Props) => {
  const [revisionData, setRevisionData] = useState<Array<object>>([]);

  let filterArr = data.filter((revision: any) => {
    return revision.clicks > 0 && revision.impressions > 0;
  });

  useEffect(() => {
    setRevisionData(filterArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card height="510px">
      <Group position="apart">
        <h1 className="page_title">Revision table</h1>
        <Group position="right">
          <Menu placement="end">
            <Menu.Label>Table</Menu.Label>
            {revisionData.length < data.length ? (
              <Menu.Item
                icon={<Visible size={14} color={variables.prime_dark} />}
                disabled={revisionData.length > data.length}
                onClick={() => setRevisionData(data)}
              >
                Show all
              </Menu.Item>
            ) : (
              <Menu.Item
                onClick={() => setRevisionData(filterArr)}
                icon={<Hide size={14} color={variables.prime_dark} />}
              >
                Hide all zero values
              </Menu.Item>
            )}
          </Menu>
        </Group>
      </Group>
      <Table data={revisionData} headers={headers} title={title} />
    </Card>
  );
};

export default TableStats;
