import React, { useState, useEffect } from 'react';
import { Base_url, fetchOptions } from 'components/utils/utils';
import { useForm } from 'react-hook-form';
import {
  useAppDispatch,
  useAppSelector,
} from 'components/utils/hooks/useRedux';
import { AuthState } from '@okta/okta-auth-js';

import './Autocomplete.scss';

type Props = {
  db?: string;
  site?: string;
  auth?: AuthState;
};

const Autocomplete = ({ db = '2', site, auth }: Props) => {
  const { register, handleSubmit } = useForm();
  const [suggestions, setSuggestions] = useState<Array<string>>([]);
  const [suggestionText, setSuggestionText] = useState<string>('');
  const [dataIsLoading, setIsLoadingData] = useState<boolean>(false);
  const [optionDataLoading, setOptionDataLoading] = useState<boolean>(false);
  const [optionData, setOptionData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const getStatisticsData = useAppSelector((state) => state.statsDataReducer);

  let options = fetchOptions(auth);
  const URL: string = `${Base_url}/statistics/2/newsdesk/${site}`;

  const fetchSuggestions = async (
    url: string,
    options: Object,
  ): Promise<void> => {
    setOptionDataLoading(true);
    try {
      const res = await fetch(url, options);
      const jsonData = await res.json();
      setOptionData(jsonData);
      setOptionDataLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuggestions(URL, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL]);

  useEffect(() => {
    if (getStatisticsData.searchString !== '') {
      setSuggestionText(decodeURI(getStatisticsData.searchString));
    }
  }, [getStatisticsData.searchString]);

  const fetchData = async (
    url: string,
    options: Object,
    searchString: string,
  ): Promise<void> => {
    setIsLoadingData(true);
    try {
      const res = await fetch(url, options);
      const jsonData = await res.json();
      dispatch({
        type: 'SET_STATS_DATA',
        payload: jsonData,
        loading: dataIsLoading,
        searchString: searchString,
      });
      setIsLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSuggestionText('');
    dispatch({
      type: 'SET_STATS_DATA',
      payload: null,
      loading: false,
      searchString: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const handleRegistration = (searchString: any) => {
    const searchResult = encodeURI(searchString);
    fetchData(
      `${Base_url}/v2/statistics/${db}/newsdesk/${site}/${searchResult}`,
      options,
      searchString,
    );
  };

  const onTextChange = (e: any) => {
    const value = e.target.value;
    let suggestionsArray = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, 'i');
      suggestionsArray = optionData.article_Ids.filter((v: any) =>
        regex.test(v),
      );
    }
    setSuggestions(suggestionsArray);
    setSuggestionText(value);
  };

  const suggestionSelected = (value: string) => {
    setSuggestions([]);
    setSuggestionText(decodeURI(value));
    handleRegistration(value);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleRegistration)}>
      <div className="autocomplete">
        <input
          id="completeInput"
          placeholder="Search"
          type="search"
          {...register('articleID')}
          onChange={onTextChange}
          value={suggestionText}
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Search')}
        />
        {!optionDataLoading &&
        optionData.article_Ids !== undefined &&
        suggestions.length > 0 ? (
          <ul>
            {suggestions.map((item: string, index: number) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    suggestionSelected(item);
                  }}
                >
                  {decodeURI(item)}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </form>
  );
};

export default Autocomplete;
