import React, { useState } from 'react';
import { Group } from '@mantine/core';
import Card from 'components/Card';
import Linechart from './Linechart';
import { HistoryObject } from './Types';
import { numberFormate } from '../utils';

import variables from 'global.module.scss';
import './styles/HistoryChart.scss';
import { useDisclosure } from '@mantine/hooks';
import FilterMenu from './FilterMenu';

type Props = {
  data: Array<HistoryObject>;
};

const HistoryCharts = ({ data }: Props) => {
  const [value, setValue] = useState<string>('impressions');
  const [opened, handlers] = useDisclosure(false);

  // Formate a date string from YYYY-MM-DD to DD.MM
  const formateDate = (dateString: string) => {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(dateString)) {
      const formateArray: Array<string> = dateString.split('-');
      return `${formateArray[2]}.${formateArray[1]}`;
    }
    return dateString;
  };

  // Filter out all impressions less than 30
  const filterDataImps = () => {
    return data?.filter((item: HistoryObject) => {
      return item.impressions >= 30;
    });
  };

  // Filter out all clicks less than 10
  const filterDataClicks = () => {
    return data?.filter((item: HistoryObject) => {
      return item.clicks >= 10;
    });
  };

  return (
    <Card height="245px">
      <Group position="apart">
        <h1 className="page_title">{`Historical ${value}`}</h1>
        <FilterMenu
          opened={opened}
          open={handlers.open}
          close={handlers.close}
          value={value}
          setValue={setValue}
        />
      </Group>
      {filterDataImps().length !== 0 || filterDataClicks().length !== 0 ? (
        <div>
          <Linechart
            data={
              value === 'impressions' ? filterDataImps() : filterDataClicks()
            }
            fill={variables.stats_blue}
            dataKey={value === 'impressions' ? 'impressions' : 'clicks'}
            yFormate={numberFormate}
            xFormate={formateDate}
          />
        </div>
      ) : (
        <p className="no-content">No content to load</p>
      )}
    </Card>
  );
};

export default HistoryCharts;
