import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import useStateIfMounted from './utils/hooks/useStateIfMounted';
import UserAccount from './account/UserAccount';

import './styles/Header.scss';

const Header: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useStateIfMounted<any>(null);

  const path = /(\/partnerstudio\/app\/mab)/.test(window.location.pathname)
    ? window.location.pathname.replace('/partnerstudio/app/mab', '')
    : window.location.pathname;

  const query = new URLSearchParams(window.location.search);

  const logout = async (): Promise<void> => {
    await oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
  };

  const pathChange = (path: string): string => {
    let newPath = path.substring(1).split('/').join('  /  ');
    let queryValues = query.has('site') ? query.getAll('site') : [];
    return (
      newPath.charAt(0).toUpperCase() +
      newPath.slice(1) +
      (query.has('site') ? '  /  ' + queryValues.join('  /  ') : '')
    );
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then((user) => {
        setUserInfo(user);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authState) return null;

  return (
    <div className="header">
      <h3>{pathChange(path)}</h3>
      {userInfo ? <UserAccount userInfo={userInfo} logout={logout} /> : null}
    </div>
  );
};

export default Header;
