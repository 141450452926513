import React from 'react';
import { ScrollArea, Table, Tooltip } from '@mantine/core';
import Card from 'components/Card';

import variables from 'global.module.scss';

type Props = {
  data: {
    live_ids: Array<string>;
  };
  articleData?: any;
};

const BanditTable = ({ data, articleData }: Props) => {
  const hasArticleData = articleData.length > 0;

  const getIsOptimizable = (id: string): boolean | undefined => {
    const index = articleData.findIndex((object: any) => {
      return object?.articleId === id;
    });
    return articleData[index]?.articleObject?.isOptimizable;
  };

  const getIsOptimizableText = (id: string): string => {
    const index = articleData.findIndex((object: any) => {
      return object?.articleId === id;
    });
    return articleData[index]?.articleObject?.optimizationMessage;
  };

  const rows = (data?.live_ids || []).map((row: any, index: number) => {
    return (
      <tr key={index}>
        <td>{row}</td>
        {hasArticleData ? (
          <td
            style={{
              color: variables.stats_blue,
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            <Tooltip label={getIsOptimizableText(row)}>
              {getIsOptimizable(row)?.toString()}
            </Tooltip>
          </td>
        ) : (
          <td></td>
        )}
        <td
          style={{
            color: variables.stats_blue,
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {'false'}
        </td>
      </tr>
    );
  });

  return (
    <Card width="45%" height="540px">
      <ScrollArea sx={{ height: 540 }}>
        <Table verticalSpacing="xs">
          <thead>
            <tr>
              <th>Article ID</th>
              <th style={{ textAlign: 'center' }}>Is optimizable</th>
              <th style={{ textAlign: 'center' }}>Running bandit</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
};

export default BanditTable;
