import React from 'react';
import Card from 'components/Card';
import BarChart from './BarChart';
import { calcDomainMax, desimalToPercent } from '../utils';

import './styles/OptimalCharts.scss';
import variables from 'global.module.scss';

type Props = {
  data_M: Array<any>;
  data_F: Array<any>;
  props?: any;
};

const OptimalChart = ({ data_M, data_F, props }: Props) => {
  const weightedAverage = (data: Array<any>): number => {
    const nums: number[] = data.map((item: any) => item.CTR);
    const weights: number[] = data.map((item: any) => item.impressions);
    const [sum, weightSum] = weights.reduce(
      (acc, w, i) => {
        acc[0] = acc[0] + nums[i] * w;
        acc[1] = acc[1] + w;
        return acc;
      },
      [0, 0],
    );
    return sum / weightSum;
  };

  return (
    <div className="optimal_teaser_charts">
      <div className={'flex-item-left'}>
        <Card width="auto" height="350px">
          <div className="title_container">
            <h2>Optimal CTR - Male</h2>
          </div>
          {data_M.length > 0 ? (
            <BarChart
              fill={variables.stats_blue}
              data={data_M}
              title="Optimal male"
              xKey="age_segment"
              barKey={['CTR']}
              yFormate={desimalToPercent}
              average={weightedAverage(data_M)}
              averageColor={variables.stats_gray}
              dataMax={calcDomainMax('CTR', data_M, data_F)}
            />
          ) : (
            <p className="no-content">No content to load</p>
          )}
        </Card>
      </div>
      <div className="flex-item-right">
        <Card width="auto" height="350px">
          <div className="title_container">
            <h2>Optimal CTR - Female</h2>
          </div>
          {data_F.length > 0 ? (
            <BarChart
              fill={variables.stats_green}
              data={data_F}
              title="Optimal female"
              xKey="age_segment"
              barKey={['CTR']}
              yFormate={desimalToPercent}
              average={weightedAverage(data_F)}
              averageColor={variables.stats_gray}
              dataMax={calcDomainMax('CTR', data_M, data_F)}
            />
          ) : (
            <p className="no-content">No content to load</p>
          )}
        </Card>
      </div>
    </div>
  );
};

export default OptimalChart;
