import React, { useEffect, useState } from 'react';
import Table from './Table';
import Card from 'components/Card';

type Props = {
  data: Array<any>;
  title?: string;
};

const GeoTable = ({ data, title }: Props) => {
  const [geoData, setGeoData] = useState<Array<object>>([]);

  let filterArr = data.filter((location: any) => {
    return location.location !== 'Unknown';
  });

  useEffect(() => {
    const sort = filterArr.sort((a, b) =>
      a.impressions < b.impressions ? 1 : -1,
    );
    setGeoData(sort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card height="fit-content">
      <Table data={geoData} title={title} />
    </Card>
  );
};

export default GeoTable;
