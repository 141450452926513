import { createAction } from '@reduxjs/toolkit';

export const setDevMessage = createAction<Message>('SET_DEV_MESSAGE');

export type Message = {
  id: string;
  type: 'development';
  title: string;
  message: string;
  disallowClose?: boolean;
  autoClose?: boolean;
  closeTime?: number;
  color?: string;
  loading?: boolean;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setDevMessage,
};
