import { limitString } from 'components/utils/utils';
import parse from 'html-react-parser';

// Force page reload
export const forceReload = (): void => {
  window.location.reload();
};

// Return modal title in formate: id or id...(Collected from X duplicates)
export const createModalTitle = (id: string | number): string => {
  if (id !== undefined && typeof id === 'string') {
    return id.split('|').length === 1
      ? id
      : `${limitString(id, 10)} (Collected from ${
          id.split('|').length
        } duplicates)`;
  }
  return id === undefined ? '' : id.toString();
};

/*
 * Adds the target="_blank" attribute to all anchor-tags in the teaser-html.
 */
const _changeAnchors = (html: string) => {
  // Creates a Html-element.
  const el: HTMLHtmlElement = document.createElement('html');
  el.innerHTML = html;
  // Finds all anchor-tags in the teser html.
  const anchors: HTMLCollectionOf<HTMLAnchorElement> =
    el.getElementsByTagName('a');
  // Add the attribute to all anchor-tags with no classname or ids.
  for (let i: number = 0; i < anchors.length; i++) {
    if (
      anchors[i].hostname !== window.location.hostname &&
      anchors[i].classList.length === 0
    ) {
      anchors[i].setAttribute('target', '_blank');
    }
  }
  return el.innerHTML;
};

export const getTeaserHtml = (html: string) => {
  // Add attribute target="_blank" to all anchor tags.
  const teaserhtml: string = _changeAnchors(html) || '';
  // Parse HTML string to DOM Array:
  const parseHtml: any[] = Object.values(parse(teaserhtml));
  if (parseHtml.length > 0) {
    const filterOutTags: any[] = parseHtml.filter(
      (item) => item.type === 'body',
    );
    // Return correct React node (teaser html)
    return filterOutTags[0].props.children;
  }
  return parseHtml;
};
