import React, { useState } from 'react';
import { desimalToPercent } from 'components/pages/statistics/utils';
import { ActionIcon, Group, Stack, Tooltip } from '@mantine/core';
import Modal from './LiveStat.modal';
import { formatId } from './Utils';
import StatCards, { Style } from './LiveStat.cards';
import { TeaserObject } from '../Teaser.types';

import './styles/LiveStat.scss';
import { ReactComponent as StatsIcon } from 'components/assets/icons/barChart.svg';

type Props = {
  data: TeaserObject;
};

const LiveStat = ({ data }: Props) => {
  const [opened, setOpened] = useState(false);
  const segmentsArr: any[] = Array.from(data?.segments);

  const sortSegmentCTR = (): any[] => {
    return segmentsArr?.sort((a: any, b: any) => {
      return b['CTR'] - a['CTR'];
    });
  };

  const handleClick = () => {
    setOpened(true);
  };

  const best: any = sortSegmentCTR().filter((segment: any): any => {
    if (
      window.location.pathname.includes('vg') ||
      window.location.pathname.includes('affiliate')
    ) {
      return segment?.clicks >= 100;
    } else {
      return segment?.clicks >= 50;
    }
  });

  const statsStyle: Style = {
    ml: 0,
    h4: {
      fontSize: '1.8rem',
      paddingBottom: '',
    },
    h1: {
      fontSize: '3rem',
    },
  };

  return (
    <div className="stat-wrapper">
      <StatCards
        impressions={data?.impressions}
        ctr={data?.CTR}
        clicks={data?.clicks}
        style={statsStyle}
      />
      <hr />
      <Group align="center" position="apart">
        {segmentsArr?.length > 0 ? (
          <>
            <Stack spacing={'xs'}>
              <h1 className="ctr">Optimal segment</h1>
              <h1 className="ctr-id">CTR: {desimalToPercent(best[0]?.CTR)}</h1>
              <h1 className="ctr-id">Segment: {formatId(best[0]?.id || '')}</h1>
            </Stack>
            <Tooltip label="All segments" withArrow>
              <ActionIcon
                className="modalBtn"
                variant="hover"
                onClick={() => handleClick()}
              >
                <StatsIcon />
              </ActionIcon>
            </Tooltip>
            <Modal
              id={data?.teaserId}
              data={data}
              sortedData={segmentsArr}
              isOpen={opened}
              isClosed={() => {
                setOpened(false);
              }}
            />
          </>
        ) : null}
      </Group>
    </div>
  );
};

export default LiveStat;
