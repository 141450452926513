import React from 'react';
import { iconProps } from './iconTypes';

export const ArrowRight = ({
  color,
  viewBox = '0 0 24 24',
  size,
}: iconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      viewBox={viewBox}
      width={size}
      height={size}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
    </svg>
  );
};

export const ArrowLeft = ({
  color,
  viewBox = '0 0 24 24',
  size,
}: iconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      viewBox={viewBox}
      width={size}
      height={size}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z" />
    </svg>
  );
};
