import { useEffect } from 'react';
import { Modal } from '@mantine/core';
import { mountCorrectStyle } from 'components/pages/statistics/utils';

import './Modal.scss';
import ModalTeaser from './Modal.teaser';
import { createModalTitle } from 'components/front/utils';

type Props = {
  html: string;
  id: string;
  css: string;
  cssSource: string;
  isOpen: boolean;
  frontTool: string;
  isClosed: () => void;
};

const TeaserModal = ({
  html,
  id,
  css,
  cssSource,
  frontTool,
  isOpen,
  isClosed,
}: Props) => {
  useEffect(() => {
    if ((cssSource || css) && frontTool) {
      mountCorrectStyle(cssSource, css, frontTool);
    }
  }, [css, cssSource, frontTool]);

  return (
    <>
      <Modal
        size={'auto'}
        opened={isOpen}
        centered
        onClose={isClosed}
        title={
          <div className="modalTitle noselect" title={id}>
            {createModalTitle(id)}
          </div>
        }
      >
        <ModalTeaser
          html={html}
          id={id}
          css={css}
          cssSource={cssSource}
          frontTool={frontTool}
        />
      </Modal>
    </>
  );
};

export default TeaserModal;
