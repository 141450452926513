import { Image as IMAGE } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderImg?: string;
  errorImg?: string;
}

const Img = ({ src, placeholderImg, errorImg, ...props }: ImageProps) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);
  useEffect(() => {
    const img: HTMLImageElement = new Image();
    img.src = src as string;
    img.addEventListener('load', () => {
      setSrc(src);
    });
    img.addEventListener('error', () => {
      setSrc(errorImg || placeholderImg);
    });
  }, [src, placeholderImg, errorImg]);
  return <IMAGE {...props} src={imgSrc} />;
};

export default Img;
