import { ContextModalProps } from '@mantine/modals';
import { Text, Button, Group } from '@mantine/core';

// Base modal containing text and close button.
const Modal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  modalBody: string;
  imgSource: string;
  altImg: string;
}>) => (
  <>
    <Group position="center">
      {
        <img
          style={{ marginTop: '-2rem', marginBottom: '-2rem' }}
          src={innerProps.imgSource}
          alt={innerProps.altImg}
          height={250}
        />
      }
      <Text p="xs" align="center" pb={10} size="md">
        {innerProps.modalBody}
      </Text>
    </Group>
    <Group position="right">
      <Button mt="md" onClick={() => context.closeModal(id)}>
        Close
      </Button>
    </Group>
  </>
);
export default Modal;
