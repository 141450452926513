import React from 'react';
import { ActionIcon, Group } from '@mantine/core';
import OptimalCharts from './charts/optimal.charts';
import OptimalGroup from './optimalBar/GenderOptimalBar';
import SegmentChart from './segmentChart/segment.charts';
import GenderSegment from './segmentsBar/GenderSegmentBar';
import Tiles from './tileStatistics/Tiles.statistics';
import { useAppDispatch } from 'components/utils/hooks/useRedux';
import TableStat from './revisionTable/Table.statistics';
import HistoryCharts from './history/HistoryCharts';

import variables from 'global.module.scss';
import { ArrowLeft } from 'components/assets/icons/component_icons/arrows';
import GeoTable from './geoTable/Geo.table';

type Props = {
  statsData: any;
  articleID: string;
};

const ArticleStatistics = ({ statsData, articleID }: Props) => {
  const dispatch = useAppDispatch();

  const handleBackClick = () => {
    dispatch({
      type: 'SET_STATS_DATA',
      payload: null,
      loading: false,
      searchString: '',
    });
  };

  return (
    <>
      <Group>
        <div style={{ marginLeft: '1rem', marginBottom: '-5rem' }}>
          <ActionIcon variant="hover" onClick={() => handleBackClick()}>
            <ArrowLeft
              color={variables.prime_dark}
              size={48}
              viewBox="0 0 48 48"
            />
          </ActionIcon>
        </div>
        <h1 id="page_title">Teaser statistics</h1>
      </Group>
      <Tiles statistics={statsData.statistics} teasers={statsData.teasers} />
      <div style={{ display: 'flex' }}>
        <div className="stats_col w60">
          <TableStat
            data={statsData.teasers}
            headers={[
              { name: 'teaser id', sort: false },
              { name: 'revision', sort: false },
              { name: 'impressions', sort: true },
              { name: 'clicks', sort: true },
              { name: 'start date / time', sort: false },
              { name: 'CTR', sort: true },
            ]}
          />

          <OptimalGroup
            data={statsData.optimal_mfu}
            newsroom={statsData.newsdesk}
            articleID={articleID}
          />
          <OptimalCharts
            data_M={statsData.optimal_male}
            data_F={statsData.optimal_female}
          />
        </div>
        <div className="stats_col w40">
          <HistoryCharts data={statsData.statistics?.history} />
          <GenderSegment data={statsData.segments_sep.segments_mfu} />
          <SegmentChart
            data_M={statsData.segments_sep.segments_male}
            data_F={statsData.segments_sep.segments_female}
          />
          <GeoTable data={statsData.geography} />
        </div>
      </div>
    </>
  );
};

export default ArticleStatistics;
