import React from 'react';
import {
  desimalToPercent,
  numberFormate,
} from 'components/pages/statistics/utils';
import { Group } from '@mantine/core';

import './styles/LiveStat.scss';

type Props = {
  impressions: number;
  clicks: number;
  ctr: number;
  style?: Style;
};

export type Style = {
  ml?: string | number;
  statCard?: {
    padding?: string;
    marginBottom?: string;
  };
  h4?: {
    fontSize?: string;
    paddingBottom?: string;
  };
  h1?: {
    fontSize?: string;
  };
};

const StatCards = ({ impressions, clicks, ctr, style }: Props) => {
  return (
    <Group position="center" grow ml={style?.ml}>
      <div className="statCard">
        <h4 style={style?.h4}>Impressions</h4>
        <h1 style={style?.h1}>{numberFormate(impressions)}</h1>
      </div>
      <div className="statCard">
        <h4 style={style?.h4}>Clicks</h4>
        {<h1 style={style?.h1}>{numberFormate(clicks)}</h1>}
      </div>
      <div className="statCard">
        <h4 style={style?.h4}>CTR</h4>
        {<h1 style={style?.h1}>{desimalToPercent(ctr)}</h1>}
      </div>
    </Group>
  );
};

export default StatCards;
