import React from 'react';
import { nanoid } from 'components/utils/utils';
import { NavLink } from 'react-router-dom';

import '../styles/Sidebar.scss';
import SubList from './Navigation.SubList';

type Props = {
  nav: any;
  isActive: (path: string) => boolean;
};

const NavigationList = ({ nav, isActive }: Props) => {
  return (
    <>
      <li className="nav_row" id={isActive(nav.name) ? 'active_nav' : ''}>
        {nav.icon ? (
          <img id="icon" src={(nav as any).icon} alt={(nav as any).name} />
        ) : (
          <span style={{ marginLeft: '6.5rem' }} />
        )}
        <NavLink id="title" className="navlink" to={nav.path}>
          {nav.name}
        </NavLink>
      </li>
      {nav.sub
        ? nav.sub.map((sub: any) => {
            return (
              <ul key={nanoid()}>
                <SubList nav={nav} sub={sub} isActive={isActive} />
              </ul>
            );
          })
        : null}
    </>
  );
};

export default React.memo(NavigationList);
