import React, { ReactNode } from 'react';
import { ArticleObj } from 'actions/articleIdAction';
import { scrollToId } from 'components/utils/utils';
import Dropdown from 'components/Dropdown';
import ActionButton from 'components/ActionButton';

import { ReactComponent as SyncIcon } from 'components/assets/icons/sync.svg';

type Props = {
  articleIds: ArticleObj[];
  action: any;
  dispatchTarget:
    | 'SET_DROPDOWN_STORBY'
    | 'SET_DROPDOWN_STATISTICS'
    | 'SET_DROPDOWN_VG_TEASER_ID'
    | 'SET_DROPDOWN_STORBY_TEASER_ID'
    | 'SET_DROPDOWN_E24_TEASER_ID'
    | 'SET_DROPDOWN_AFFILIATE_TEASER_ID';
  startValue?: string;
  children?: ReactNode | ReactNode[];
};

const Toolbar = ({
  articleIds,
  action,
  dispatchTarget,
  startValue = 'all',
  children,
}: Props) => {
  const onChangeDropdown = (id: string) => scrollToId(id);

  const getDopdownOptions: any = (arr: Array<string>) => {
    let option = arr.map((id: any) => {
      return { name: id.articleId, value: id.articleId, default: false };
    });
    return [...option];
  };

  const teaserIds: Array<object> = [
    { name: 'No selected', value: 'all', default: true },
    ...getDopdownOptions(articleIds),
  ];

  return (
    <div className="toolbar">
      <Dropdown
        dispatchTarget={dispatchTarget}
        options={teaserIds}
        startValue={startValue}
        condition={articleIds.length > 0}
        onChange={onChangeDropdown}
      />
      {children}
      <ActionButton icon={<SyncIcon />} action={action} tooltip="Sync" />
    </div>
  );
};

export default Toolbar;
