import React from 'react';
import Card from 'components/Card';

type Props = {
  data: {
    live_ids: Array<string>;
  };
  articleData?: any;
};

const BanditStat = ({ data, articleData }: Props) => {
  const hasArticleData = articleData.length > 0;

  const getIsOptmailCount = (): number => {
    let isOptimal: Array<boolean> = [];
    // eslint-disable-next-line array-callback-return
    articleData?.map((item: any) => {
      if (item?.articleObject?.isOptimizable === true) {
        isOptimal.push(true);
      }
    });
    return isOptimal.length;
  };

  return (
    <div style={{ width: '55%' }}>
      <div style={{ display: 'flex' }}>
        <Card width="100%" height="10%">
          <h4>Running bandits</h4>
          <h1>{0}</h1>
        </Card>
        <Card width="100%" height="10%">
          <h4>Live articles</h4>
          {<h1>{data?.live_ids ? Array.from(data?.live_ids).length : null}</h1>}
        </Card>
        {hasArticleData ? (
          <Card width="100%" height="10%">
            <h4>Is optimizable</h4>
            {<h1>{getIsOptmailCount()}</h1>}
          </Card>
        ) : null}
      </div>
      <div style={{ display: 'flex' }}>
        <Card width="100%" height="400px">
          <div />
        </Card>
      </div>
    </div>
  );
};

export default BanditStat;
