import React, { useEffect, useRef, useState } from 'react';
import Card from 'components/Card';
import { AuthState } from '@okta/okta-auth-js/lib/types';
import { Base_url, fetchOptions } from 'components/utils/utils';
import { Table } from '@mantine/core';
import { useAppDispatch } from 'components/utils/hooks/useRedux';

type Props = {
  auth: AuthState;
  site: string;
};

const LiveTable = ({ auth, site }: Props) => {
  const [dataIsLoading, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingStats, setIsLoadingStats] = useState<boolean>(false);
  const [data, setData] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const cancelRequest = useRef<boolean>(false);

  let options = fetchOptions(auth);

  // Fetching all live article ids for given site
  const fetchLiveIds = async (
    url: string,
    options: Object,
    controller: AbortController | any | undefined,
  ): Promise<void> => {
    const opt = { ...options, ...{ signal: controller.signal } };
    setIsLoadingData(true);
    try {
      const res = await fetch(url, opt);
      const jsonData = await res.json();
      setData(jsonData?.live_ids);
      if (cancelRequest.current) return;
      setIsLoadingData(false);
    } catch (error: any) {
      if (cancelRequest.current) return;
      console.log(error);
    }
  };

  // Fetching statistics for Article and adding this to redux
  const fetchStatData = async (
    url: string,
    options: Object,
    searchString: string,
  ): Promise<void> => {
    setIsLoadingStats(true);
    try {
      const res = await fetch(url, options);
      const jsonData = await res.json();
      dispatch({
        type: 'SET_STATS_DATA',
        payload: jsonData,
        loading: dataIsLoading,
        searchString: searchString,
      });
      if (cancelRequest.current) return;
      setIsLoadingStats(false);
    } catch (error: any) {
      if (cancelRequest.current) return;
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchLiveIds(`${Base_url}/articles/live/2/${site}`, options, controller);

    return () => {
      controller.abort();
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const handleClick = (searchString: any) => {
    const searchResult = encodeURI(searchString);
    fetchStatData(
      `${Base_url}/v2/statistics/${2}/newsdesk/${site}/${searchResult}`,
      options,
      searchString,
    );
  };

  // Creating rows for table
  const rows = data?.map((id) => (
    <tr key={id} onClick={() => handleClick(id)}>
      <td>{id}</td>
    </tr>
  ));

  return (
    <>
      {(!dataIsLoading || !isLoadingStats) && data?.length > 0 ? (
        <>
          <h1 id="page_title">Live articles</h1>
          <Card width="650px">
            <Table highlightOnHover>
              <thead>
                <tr>
                  <th>Current live ids</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </Card>
        </>
      ) : null}
    </>
  );
};

export default LiveTable;
