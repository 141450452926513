import React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Bar,
  CartesianGrid,
  ReferenceLine,
} from 'recharts';
import { ImplicitLabelType } from 'recharts/types/component/Label';
import { desimalToPercent } from '../utils';
import CustomTooltip from './Tooltip.custom';
import { nanoid } from 'components/utils/utils';

type Props = {
  data: Array<any>;
  title?: string;
  width?: string | number;
  height?: string | number;
  fill: Array<string> | string;
  xKey?: string;
  yKey?: string;
  barKey: Array<string>;
  xFormate?: any;
  yFormate?: any;
  average?: number;
  averageColor?: string;
  averageLabel?: ImplicitLabelType;
  dataMax: number;
};

const Chart = ({
  data,
  width = '90%',
  height = '80%',
  fill,
  xKey,
  yKey,
  barKey,
  xFormate,
  yFormate,
  average,
  dataMax,
  averageColor = 'red',
  averageLabel = {
    position: 'top',
    value: desimalToPercent(average),
    fill: averageColor,
    fontSize: 10,
    fontWeight: 700,
  },
}: Props) => {
  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={data}>
          <XAxis
            padding={{ left: 10, right: 10 }}
            dataKey={xKey}
            tickCount={data.length}
            tickFormatter={xFormate}
            axisLine={false}
            tickLine={false}
            tickMargin={5}
          />
          <YAxis
            tickLine={false}
            dataKey={yKey}
            axisLine={false}
            tickFormatter={(key) => yFormate(key)}
            domain={[0, dataMax]}
          />
          <Tooltip offset={50} content={<CustomTooltip />} />
          <CartesianGrid vertical={false} />
          {barKey.map((key: string, index: number) => {
            return (
              <Bar
                key={nanoid()}
                dataKey={key}
                fill={typeof fill == 'string' ? fill : fill[index]}
              />
            );
          })}
          <ReferenceLine
            strokeWidth={1.5}
            y={average}
            isFront
            label={averageLabel}
            stroke={averageColor}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;
