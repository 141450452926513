import { createAction } from '@reduxjs/toolkit';

export const setArticleIdVG = createAction<ArticleObj>('SET_ARTICLE_ID_VG');
export const setArticleIdE24 = createAction<ArticleObj>('SET_ARTICLE_ID_E24');
export const setArticleIdStorby = createAction<ArticleObj>(
  'SET_ARTICLE_ID_STORBY',
);
export const setArticleIdAffiliate = createAction<ArticleObj>(
  'SET_ARTICLE_ID_AFFILIATE',
);

export type ArticleObj = {
  articleId: string;
  articleObj: any;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setArticleIdVG,
  setArticleIdE24,
  setArticleIdStorby,
  setArticleIdAffiliate,
};
