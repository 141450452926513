import React from 'react';
import Card from 'components/Card';
import { numberFormate, desimalToPercent } from '../utils';

import './Tiles.statistics.scss';

type Props = {
  statistics: any;
  teasers: Array<any>;
};

const Tiles = ({ statistics, teasers }: Props) => {
  return (
    <div className="statistics_section">
      <Card width="100%">
        <h4>TEASERS</h4>
        <h1>{teasers.length}</h1>
      </Card>
      <Card width="100%">
        <h4>CLICKS</h4>
        <h1>{numberFormate(statistics['clicks'])}</h1>
      </Card>
      <Card width="100%">
        <h4>IMPRESSIONS</h4>
        <h1>{numberFormate(statistics['impressions'])}</h1>
      </Card>
      {!(numberFormate(statistics['count']) === 'NaN') ? (
        <Card width="100%">
          <h4>COUNT</h4>
          <h1>{numberFormate(statistics['count'])}</h1>
        </Card>
      ) : null}
      <Card width="100%">
        <h4>CTR</h4>
        <h1>{desimalToPercent(statistics['CTR'])}</h1>
      </Card>
    </div>
  );
};

export default Tiles;
