import React, { ReactNode } from 'react';
import ArrowIcon from './assets/icons/arrowRight.svg';
import Navigation from './navigation/Navigation';

import './styles/Sidebar.scss';
import psLogo from './assets/images/PS-logo/Partnerstudio_logo.svg';
import { ActionIcon } from '@mantine/core';

type Props = {
  toggle: boolean | undefined;
  handleClick: (event: React.MouseEvent<ReactNode>) => void;
};

const Sidebar: React.FC<Props> = (props) => {
  const isActive = (pathName: string) =>
    window.location.pathname.split('/').pop() === pathName.toLowerCase();
  const isClosed: boolean =
    props.toggle || props.toggle === undefined ? true : false;

  return (
    <>
      <div className="toggle_wrapper">
        <ActionIcon
          className="actionIcon"
          style={{
            marginLeft: `${isClosed ? '3.5rem' : '23.5rem'}`,
            backgroundColor: '#404d5a',
          }}
          radius="xl"
          variant="filled"
        >
          <img
            onClick={props.handleClick}
            src={ArrowIcon}
            alt="Right arrow"
            id="Icon"
            className={
              isClosed
                ? 'toggle_icon sidebar_icon'
                : 'toggle_icon sidebar_icon-open'
            }
          />
        </ActionIcon>
      </div>
      <div className="Sidebar">
        {!props.toggle ? (
          <img
            className="brandLogo"
            src={psLogo}
            width={'60%'}
            alt="Partnerstudio logo"
          />
        ) : null}
        <ul
          className={
            (props.toggle as boolean)
              ? 'nav_item_wrapper--collapse'
              : 'nav_item_wrapper'
          }
        >
          <Navigation isActive={isActive} toggle={props.toggle as boolean} />
        </ul>
      </div>
    </>
  );
};

export default React.memo(Sidebar);
