import React from 'react';
import Table from './Table';
import Card from 'components/Card';
import { fetchOptions } from 'components/utils/utils';
import useFetch from 'components/utils/hooks/useFetch';
import { AuthState } from '@okta/okta-auth-js';

type Props = {
  auth: AuthState;
  title?: string;
  url: string;
  name: string;
};

const StatusTable = ({ auth, title, url, name }: Props) => {
  let options = fetchOptions(auth);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isLoading, data } = useFetch(url, options);

  const fallbackError = {
    message: 'SERVICE UNAVAILABLE',
    uptime: '-',
    processtime: '-',
    date: new Date().toLocaleDateString('no-NO'),
  };

  return (
    <>
      <Card height="fit-content" width="auto">
        {!isLoading && data.lenght !== 0 ? (
          <Table data={data} title={title} name={name} />
        ) : (
          <Table data={fallbackError} title={title} name={name} />
        )}
      </Card>
    </>
  );
};

export default StatusTable;
