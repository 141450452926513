import React, { useState } from 'react';
import { Button, Group, Modal } from '@mantine/core';
import { Base_url, fetchOptions, nanoid } from 'components/utils/utils';
import { AuthState } from '@okta/okta-auth-js';
import { notification } from 'components/utils/Notification';
import useFetch from 'components/utils/hooks/useFetch';
import TeaserCheckbox from './Teaser.checkbox';

type Props = {
  opened: boolean;
  setOpened: (bool: boolean) => void;
  articleID: string;
  setChecked: (bool: boolean) => void;
  auth: AuthState;
  site: string;
};

const BanditModal = ({
  opened,
  setOpened,
  articleID,
  setChecked,
  auth,
  site,
}: Props) => {
  const articleIdEncode: string = encodeURIComponent(articleID);
  const [teasers, setTeasers] = useState<any>([]);

  let options = fetchOptions(auth);

  const { isLoading, data: teaserIds } = useFetch(
    `${Base_url}/report/teaser/2/newsdesk/${site}/${articleIdEncode}`,
    options,
  );

  const getIDs = (): any[] => {
    let idsArr: any[] = [];
    // eslint-disable-next-line array-callback-return
    Array.from(teaserIds[articleID] || []).map((ids: any) => {
      idsArr.push(ids?.id);
    });
    return idsArr;
  };

  const startBandit = async () => {
    try {
      await fetch(`${Base_url}/bandits/start/${site}_${articleIdEncode}`, {
        method: 'POST',
        headers: options?.headers,
        body: JSON.stringify({
          requested: Date.now(),
          started: true,
          teasers: teasers,
        }),
      }).then(() => {
        notification({
          id: nanoid(),
          message: `The bandit for ${articleID} has succsesfully started on ${site}`,
          title: `Started bandit: ${articleID}`,
          type: 'info',
          autoClose: false,
          loading: false,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        size="100%"
        opened={opened}
        onClose={() => setOpened(false)}
        title={`Choose teasers for article: ${articleID}`}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <TeaserCheckbox
          data={!isLoading ? getIDs() : []}
          teasers={teasers}
          setTeasers={setTeasers}
        />
        <Group mt="xl">
          <Button
            onClick={() => {
              startBandit();
              setOpened(false);
            }}
          >
            Start bandit
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              setOpened(false);
              setChecked(false);
            }}
          >
            Close
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default BanditModal;
