import React, { forwardRef } from 'react';
import { Group, Avatar, UnstyledButton } from '@mantine/core';

import variables from 'global.module.scss';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image?: string;
  acronym: string;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, acronym, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
      })}
      {...others}
    >
      <Group>
        <Avatar
          size="md"
          radius="xl"
          styles={{
            placeholder: {
              color: variables.site_bg,
              backgroundColor: variables.prime_dark,
              '&:hover': {
                opacity: '0.9',
              },
              fontSize: '1.8rem',
              fontWeight: 'normal',
            },
          }}
        >
          {acronym}
        </Avatar>
      </Group>
    </UnstyledButton>
  ),
);

export default React.memo(UserButton);
