import React from 'react';
import { nanoid } from 'components/utils/utils';
import NavigationCollapsed from './NavigationList.Collapsed';
import { NavLinks } from '../utils/NavLinks';
import NavigationList from './NavigationList';

import '../styles/Sidebar.scss';

type Props = {
  isActive: (path: string) => boolean;
  toggle: boolean;
};

const Navigation = ({ isActive, toggle }: Props) => {
  const navigation: JSX.Element[] = NavLinks.map((nav: any) => {
    if (toggle) {
      return (
        <div key={nanoid()}>
          <NavigationCollapsed nav={nav} isActive={isActive} />
        </div>
      );
    } else {
      return (
        <div key={nanoid()}>
          <NavigationList nav={nav} isActive={isActive} />
        </div>
      );
    }
  });

  return <>{navigation}</>;
};

export default React.memo(Navigation);
