import { createReducer } from '@reduxjs/toolkit';
import { ArticleObj } from '../actions/articleIdAction';
import {
  setArticleIdVG,
  setArticleIdE24,
  setArticleIdStorby,
  setArticleIdAffiliate,
} from './../actions/articleIdAction';

type State = {
  articleIdsVG: Array<ArticleObj>;
  articleIdsE24: Array<ArticleObj>;
  articleIdsStorby: Array<ArticleObj>;
  articleIdsAffiliate: Array<ArticleObj>;
};

const initSate: State = {
  articleIdsVG: [],
  articleIdsE24: [],
  articleIdsStorby: [],
  articleIdsAffiliate: [],
};

const addArticleId = (obj: ArticleObj, array: Array<ArticleObj>) => {
  if (!array.some((e) => e['articleId'] === obj.articleId)) {
    array = [...array, obj];
  }
  return array;
};

const articleIdReducer = createReducer(initSate, (builder) => {
  builder
    .addCase(setArticleIdVG, (state, action) => {
      state.articleIdsVG = addArticleId(action.payload, state.articleIdsVG);
    })
    .addCase(setArticleIdE24, (state, action) => {
      state.articleIdsE24 = addArticleId(action.payload, state.articleIdsE24);
    })
    .addCase(setArticleIdStorby, (state, action) => {
      state.articleIdsStorby = addArticleId(
        action.payload,
        state.articleIdsStorby,
      );
    })
    .addCase(setArticleIdAffiliate, (state, action) => {
      state.articleIdsAffiliate = addArticleId(
        action.payload,
        state.articleIdsAffiliate,
      );
    })
    .addDefaultCase((state, action) => state);
});

export default articleIdReducer;
