import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import CustomTooltip from './Tooltip.custom';

type Props = {
  data: Array<any>;
  title?: string;
  dataMax: number;
  width?: string | number;
  height?: string | number;
  fill: string;
  xKey?: string;
  yKey?: string;
  barKey: string;
  xFormate?: any;
  yFormate?: any;
};

const Chart = ({
  data,
  width = '100%',
  height = '45%',
  fill,
  xKey,
  yKey,
  barKey,
  xFormate,
  yFormate,
  dataMax,
}: Props) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{ top: 10, bottom: 5, left: 2, right: -10 }}
      >
        <CartesianGrid horizontal={false} vertical={false} />
        <XAxis
          type="number"
          padding={{ left: 10, right: 0 }}
          axisLine={false}
          tickLine={false}
          tick={false}
          tickMargin={0}
          tickCount={data.length}
          dataKey={xKey}
          domain={['auto', dataMax]}
        />
        <YAxis
          dataKey={yKey}
          type="category"
          axisLine={true}
          tickLine={false}
          tickMargin={5}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey={barKey}
          barSize={10}
          fill={typeof fill == 'string' ? fill : ''}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Chart;
