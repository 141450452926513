import React from 'react';
import { NavLink } from 'react-router-dom';

import '../styles/Sidebar.scss';

type Props = {
  nav: any;
  sub: any;
  isActive: (path: string) => boolean;
};

const SubList = ({ nav, isActive, sub }: Props) => {
  return (
    <>
      <li
        className="nav_row"
        id={isActive(nav.path + sub.url) ? 'active_nav' : ''}
      >
        <div
          className="nav_row sub_row"
          id={isActive(nav.path + sub.url) ? 'active_nav' : ''}
        >
          {sub.icon ? <img id="icon" src={sub.icon} alt={sub.name} /> : null}
          <NavLink id="title" className="navlink" to={nav.path + sub.url}>
            {sub.name}
          </NavLink>
        </div>
      </li>
    </>
  );
};

export default React.memo(SubList);
