import React from 'react';
import { Modal } from '@mantine/core';
import StatsTable from './LiveStat.table';
import StatCards from './LiveStat.cards';
import { createModalTitle } from '../utils';
import { Segments, TeaserObject } from '../Teaser.types';

type Props = {
  id: string;
  isOpen: boolean;
  data: TeaserObject;
  sortedData: Segments[];
  isClosed: () => void;
};

const TeaserModal = ({ id, isOpen, isClosed, data, sortedData }: Props) => {
  return (
    <>
      <Modal
        size={'auto'}
        opened={isOpen}
        centered
        onClose={isClosed}
        title={
          <div className="modalTitle noselect" title={id}>
            {createModalTitle(id)}
          </div>
        }
      >
        {data ? (
          <div className="modalContent" id={id}>
            <StatCards
              impressions={data?.impressions}
              clicks={data?.clicks}
              ctr={data?.CTR}
            />
            <StatsTable data={sortedData} />
          </div>
        ) : (
          <p className="no-content">No content to load</p>
        )}
      </Modal>
    </>
  );
};

export default TeaserModal;
