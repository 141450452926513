import React from 'react';
import { iconProps } from './iconTypes';

export const Unfold = ({ color, viewBox = '0 0 24 24', size }: iconProps) => {
  return (
    <svg
      fill={color}
      viewBox={viewBox}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.2 17.4L15 15.2l9-9 9 9-2.2 2.2-6.8-6.8zM24 42l-9-9 2.2-2.2 6.8 6.8 6.8-6.8L33 33z" />
    </svg>
  );
};

export const Down = ({ color, viewBox = '0 0 24 24', size }: iconProps) => {
  return (
    <svg
      fill={color}
      viewBox={viewBox}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 30.75l-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8z" />
    </svg>
  );
};

export const Up = ({ color, viewBox = '0 0 24 24', size }: iconProps) => {
  return (
    <svg
      fill={color}
      viewBox={viewBox}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.15 30.75L12 28.6l12-12 12 11.95-2.15 2.15L24 20.85z" />
    </svg>
  );
};
