import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { rumInit } from 'datadog/datadogInit';
import variables from 'global.module.scss';
import Modal from 'components/modals/Modal.basic';

import store from './store';

// Init Datadog rum:
rumInit();

const brand_colors: any = variables.brand.split(',');
const basename: string = process.env.NODE_ENV === 'development' ? '/' : '/';

const rootTheme: MantineThemeOverride | undefined = {
  colors: {
    brand: brand_colors,
  },
  primaryColor: 'brand',
  fontFamily: variables.fontFamily,
};

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider theme={rootTheme} withCSSVariables withNormalizeCSS>
      <ModalsProvider modals={{ baseModal: Modal }}>
        <NotificationsProvider>
          <Provider store={store}>
            <Router basename={basename}>
              <App />
            </Router>
          </Provider>
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
