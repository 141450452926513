import React from 'react';
import {
  isoFormate,
  mountCorrectStyle,
} from 'components/pages/statistics/utils';
import LiveStat from './liveStat/LiveStat';
import { nanoid } from 'components/utils/utils';
import { Card, Group } from '@mantine/core';
import { getTeaserHtml } from './utils';
import { TeaserObject } from './Teaser.types';

import './styles/Teasers.scss';

type Props = {
  data: Array<TeaserObject>;
  totalImps: number;
  filterType: string;
};

const Teaser = ({ data, totalImps, filterType }: Props): JSX.Element => {
  return (
    <>
      {data?.map((teaser: any) => {
        const htmlString: string = teaser?.apiHtml?.toString() || '';
        mountCorrectStyle(teaser.cssSource, teaser.css, teaser.frontTool);
        if (
          teaser.apiHtml &&
          teaser.content !== null &&
          {
            /* teaser?.impressions >= totalImps / 100 */
          }
        ) {
          return (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={nanoid()}
              className="teaser-wrapper"
              id={teaser.teaserId}
            >
              {filterType === 'notLive' ? (
                <p id="teaserDate">
                  Live on {isoFormate(teaser?.firstAppearanceReadable)}
                </p>
              ) : null}
              <Card shadow="sm" p="lg" pb={40} radius="md" withBorder>
                <Card.Section p="lg">{getTeaserHtml(htmlString)}</Card.Section>
                <Group>{<LiveStat data={teaser} />}</Group>
              </Card>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default Teaser;
