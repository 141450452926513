import { AuthState } from '@okta/okta-auth-js';

/*
 * Component utils
 */

export const Base_url =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:5000'
    : 'https://mab.partnerstudio.io/api';

export const scrollToId = (id: string) => {
  const section: Element | null = document.querySelector(`#${CSS.escape(id)}`);
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const capitalizeFirstLetter = (str: string) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
};

export const limitString = (str: string, limit: number): string => {
  return str.length < limit
    ? str.substring(0, limit)
    : `${str.substring(0, limit)}...`;
};

/*
 * @params AuthState from @okta/okta-auth-js
 * @returns Fetch options Object
 */
type FetchOption = {
  Method: string;
  headers: {
    Accept: string;
    'Content-Type': string;
    Authorization: string;
  };
};

export const fetchOptions = (auth: AuthState | undefined): FetchOption => {
  const access = auth?.accessToken;
  return {
    Method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access?.accessToken}`,
    },
  };
};

export let nanoid = (t: number = 21): string =>
  crypto
    .getRandomValues(new Uint8Array(t))
    .reduce(
      (t, e) =>
        (t +=
          (e &= 63) < 36
            ? e.toString(36)
            : e < 62
            ? (e - 26).toString(36).toUpperCase()
            : e > 62
            ? '-'
            : '_'),
      '',
    );
