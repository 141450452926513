import { Base_url } from './../components/utils/utils';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

// Containing all Datadog initiations

// Init Datadog rum:
datadogRum.init({
  applicationId: process.env.REACT_APP_APPLICATION_ID as string,
  clientToken: process.env.REACT_APP_DATADOG_TOKEN as string,
  site: process.env.REACT_APP_DATADOG_SITE,
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: 'prod',
  version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [Base_url + '/'],
});

// Init Datadog browser logging:
datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_TOKEN as string,
  site: process.env.REACT_APP_DATADOG_SITE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: 'prod',
  version: '1.0.0',
  forwardConsoleLogs: ['log', 'warn', 'error'],
});

export const rumInit = () => {
  if (process.env.NODE_ENV === 'production') {
    datadogRum.startSessionReplayRecording();
  }
};
