import { createStyles } from '@mantine/core';

// Returns the mantine spesific component styles
export const useStyles = createStyles((theme) => ({
  progressLabel: {
    lineHeight: 16,
    fontSize: 14,
    color: '#fcfcfc',
  },

  stat: {
    borderBottom: '3px solid',
    paddingBottom: 5,
  },

  statCount: {
    fontFamily: `Nunito, sans-serif`,
    lineHeight: 1.3,
  },

  total: {
    fontFamily: `Nunito, sans-serif`,
    display: 'flex',
    fontSize: 24,
  },
}));
