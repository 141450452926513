import React from 'react';
import { capitalizeFirstLetter, nanoid } from 'components/utils/utils';
import { Badge } from '@mantine/core';

type Helth = {
  uptime: string;
  processtime: string;
  message: string;
  date: string;
};

type Props = {
  title?: string;
  data: Helth;
  name: string;
};

const chooseDescription = (str: string) => {
  if (str === 'uptime') {
    return 'The number of seconds the current Node.js process has been running.';
  } else if (str === 'processtime') {
    return 'The code execution time';
  }
};

const Table = ({ title, data, name }: Props) => {
  const headers: string[] = Object.keys(data);
  const values: (string | number)[] = Object.values(data);
  return (
    <div className="table">
      <table id="table" cellSpacing={0}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            {headers.map((title) => {
              return (
                <th title={chooseDescription(title)} key={nanoid()}>
                  {capitalizeFirstLetter(title)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody title={`${title}: ${data.message}`}>
          <tr>
            <td>
              {
                <Badge size="md" variant="filled">
                  {name}
                </Badge>
              }
            </td>
            <td
              style={{
                fontSize: '3rem',
                color: data?.message === 'OK' ? '#1abc9c' : '#bc1a1a',
              }}
            >
              &#8226;
            </td>
            {values.map((value) => {
              return <td key={nanoid()}>{value}</td>;
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
