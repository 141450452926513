import React from 'react';
import Dropdown from 'components/Dropdown';
import { AuthState } from '@okta/okta-auth-js';
import Spots from 'components/front/Spots';
import { forceReload } from 'components/front/utils';
import { useAppSelector } from 'components/utils/hooks/useRedux';
import Toolbar from 'components/toolbar/Toolbar';

import './styles/dashboard.storby.scss';

type Props = {
  auth: AuthState;
};

const Storby = ({ auth }: Props) => {
  const getArticleIDs = useAppSelector((state) => state.articleidreducer);

  const media = [
    { name: 'All', value: 'all', default: true },
    { name: 'Aftenposten', value: 'ap', default: false },
    { name: 'Fædrelandsvennen', value: 'fvn', default: false },
    { name: 'Bergens Tidende', value: 'bt', default: false },
    { name: 'Stavanger Aftenblad', value: 'sa', default: false },
    { name: 'Adressa', value: 'adressa', default: false },
  ];

  return (
    <div className="subpg_wrapper">
      <Toolbar
        articleIds={getArticleIDs.articleIdsStorby}
        action={forceReload}
        dispatchTarget={'SET_DROPDOWN_STORBY_TEASER_ID'}
      >
        <Dropdown
          dispatchTarget={'SET_DROPDOWN_STORBY'}
          options={media}
          startValue="all"
        />
      </Toolbar>
      <Spots auth={auth} site="storby" dispatchTarget="SET_ARTICLE_ID_STORBY" />
    </div>
  );
};

export default Storby;
