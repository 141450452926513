import React from 'react';
import Teaser from './Teaser';
import { TeaserObject } from './Teaser.types';

import './styles/Teasers.scss';

type Props = {
  filter: Array<TeaserObject>;
  totalImps: number;
  filterType: string;
};

const Teasers = ({ filter, totalImps, filterType }: Props) => {
  const sortTeaserFilter = (): any => {
    return filter?.sort((a: any, b: any) => {
      return b['CTR'] - a['CTR'];
    });
  };

  return (
    <>
      {filter?.length > 0 ? (
        <div className="scaleContainer">
          <Teaser
            data={sortTeaserFilter()}
            totalImps={totalImps}
            filterType={filterType}
          />
        </div>
      ) : (
        <p className="no-content">No content to load</p>
      )}
    </>
  );
};

export default Teasers;
