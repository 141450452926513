import React, { useEffect, useState } from 'react';
import { ScrollArea } from '@mantine/core';
import { nanoid } from 'components/utils/utils';
import { desimalToPercent, numberFormate } from '../utils';

//import './Table.scss';

type Props = {
  title?: string;
  data: Array<any>;
};

const Table = ({ title, data }: Props) => {
  const [sortedData, setSortedData] = useState<Array<any>>(data);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const renderTableData = (): JSX.Element[] => {
    return sortedData.map((row: any) => {
      return (
        <tr key={nanoid()}>
          <td>{row.location}</td>
          <td>{numberFormate(row.impressions)}</td>
          <td>{numberFormate(row.clicks)}</td>
          <td>{desimalToPercent(row.CTR)}</td>
        </tr>
      );
    });
  };

  return (
    <div className="table">
      <h1 className="table_title">{title}</h1>
      <ScrollArea
        // eslint-disable-next-line no-restricted-globals
        sx={{ height: 500 }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <table id="table" cellSpacing={0}>
          <thead>
            <tr>
              <th>Location</th>
              <th>Impressions</th>
              <th>Clicks</th>
              <th>CTR</th>
            </tr>
          </thead>
          <tbody>{data.length > 0 ? renderTableData() : null}</tbody>
        </table>
        {data.length === 0 ? (
          <p className="no-content">No content to load</p>
        ) : null}
      </ScrollArea>
    </div>
  );
};

export default Table;
