import React from 'react';
import Card from 'components/Card';
import { numberFormate } from '../utils';
import { capitalizeFirstLetter } from 'components/utils/utils';

const Tooltip = ({ active, payload, label }: any) => {
  // Formate a date string from YYYY-MM-DD to DD-MM-YYYY
  const formateDate = (date: string) => {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date)) {
      const dateArray: Array<string> = date.split('-');
      return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }
  };

  if (active) {
    return (
      <Card height="20px">
        <h3 className="tooltip_header">{formateDate(label)}</h3>
        <p
          className="label"
          style={{ marginTop: '-1rem' }}
        >{`${capitalizeFirstLetter(payload[0].name)}: ${numberFormate(
          payload[0].value,
        )}`}</p>
      </Card>
    );
  }

  return null;
};

export default Tooltip;
