import React, { ReactElement } from 'react';
import { ActionIcon, ActionIconVariant, Tooltip } from '@mantine/core';

import './styles/ActionBtn.scss';

type Props = {
  icon: React.FC<React.SVGProps<SVGSVGElement>> | SVGRectElement | ReactElement;
  tooltip?: string;
  action: () => void;
  variant?: ActionIconVariant | undefined;
};

const ActionButton = ({
  icon,
  tooltip,
  action,
  variant = 'outline',
}: Props) => {
  return (
    <>
      {tooltip ? (
        <Tooltip label={tooltip} withArrow>
          <ActionIcon className="actionBtn" variant={variant} onClick={action}>
            {icon}
          </ActionIcon>
        </Tooltip>
      ) : (
        <ActionIcon className="actionBtn" variant={variant} onClick={action}>
          {icon}
        </ActionIcon>
      )}
    </>
  );
};

export default ActionButton;
