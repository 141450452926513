import React, { useState, useEffect } from 'react';
import { useAppDispatch } from './utils/hooks/useRedux';

import './styles/Dropdown.scss';
import { useHistory } from 'react-router-dom';

type Props = {
  options: Array<Object>;
  startValue: string;
  condition?: boolean;
  onChange?: (active: string) => void;
  dispatchTarget:
    | 'SET_DROPDOWN_STORBY'
    | 'SET_DROPDOWN_STATISTICS'
    | 'SET_DROPDOWN_VG_TEASER_ID'
    | 'SET_DROPDOWN_STORBY_TEASER_ID'
    | 'SET_DROPDOWN_E24_TEASER_ID'
    | 'SET_DROPDOWN_AFFILIATE_TEASER_ID';
};

const Dropdown = ({
  options,
  startValue,
  dispatchTarget,
  condition = true,
  onChange,
}: Props) => {
  const [active, setActive] = useState<string>(startValue);
  const dispatch = useAppDispatch();

  const history = useHistory();

  const addQuery = (event: any) => {
    if (event.target.value === 'all') {
      history.push(window.location.pathname);
    } else {
      history.push(`?site=${event.target.value}`);
    }
  };

  const handelOnChange = (event: any) => {
    setActive(event.target.value);
    dispatch({ type: dispatchTarget, payload: event.target.value });
    if (onChange) {
      onChange(event.target.value);
    } else {
      addQuery(event);
    }
  };

  useEffect(() => {
    dispatch({ type: 'RESET_DROPDOWN' });
    if (window.location.href.includes('?site=')) {
      history.push(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dropdown">
      {condition ? (
        <select
          name="Newspapers"
          value={active}
          onChange={(event) => handelOnChange(event)}
        >
          {options.map((option: any, index: number) => {
            return (
              <option
                key={index}
                value={option.value}
                defaultValue={option.default}
              >
                {option.name}
              </option>
            );
          })}
        </select>
      ) : null}
    </div>
  );
};

export default Dropdown;
