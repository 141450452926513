import React from 'react';
import Card from 'components/Card';
import { desimalToPercent, numberFormate } from '../utils';

import './styles/Tooltip.scss';

const Tooltip = ({ active, payload, label }: any) => {
  const getFormat = (value: number) => {
    if (value % 1 !== 0) {
      return desimalToPercent(value);
    }
    return numberFormate(value);
  };

  if (active) {
    return (
      <Card height="35px">
        <h3 className="tooltip_header_s">{`${label}`}</h3>
        <p className="label_s">{`Gender: ${payload[0]?.payload.gender_segment}`}</p>
        <p className="label_s">{`${payload[0]?.dataKey} : ${getFormat(
          payload[0]?.value,
        )}`}</p>
      </Card>
    );
  }

  return null;
};

export default Tooltip;
