import { combineReducers } from '@reduxjs/toolkit';
import dropDownReducer from './dropDownReducer';
import articleidreducer from './articleIdReducer';
import statsDataReducer from './statsDataReduce';
import devMessageReducer from './devMessageReducer';

const rootReducer = combineReducers({
  dropDownReducer,
  articleidreducer,
  statsDataReducer,
  devMessageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
