import React from 'react';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import { useOktaAuth } from '@okta/okta-react';
import usePersistedState from 'components/utils/hooks/usePersistedState';
import { Badge } from '@mantine/core';
import StatusTable from './statusTable/Status.table';
import { Base_url } from 'components/utils/utils';
import Card from 'components/Card';
import { useAppSelector } from 'components/utils/hooks/useRedux';

import './Settings.scss';

const Settings = () => {
  const [isClosed, toggle] = usePersistedState('Sidemenu', false);
  const { authState } = useOktaAuth();
  const getDevMessage = useAppSelector((state) => state.devMessageReducer);

  const url: string =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:9100'
      : 'https://bandit.partnerstudio.io';

  if (!authState) return null;

  const getVersion = (): string => {
    if (process.env.REACT_APP_VERSION) {
      return process.env.REACT_APP_VERSION;
    }
    return '';
  };

  return (
    <div
      className="parent"
      style={isClosed ? { gridTemplateColumns: '5rem' } : {}}
    >
      <div className="sideBar_container">
        <Sidebar
          toggle={isClosed}
          handleClick={() => {
            toggle(!isClosed);
          }}
        />
      </div>
      <div className="dashboard_container">
        <Header />
        <div className="pg_wrapper">
          <div className="settings">
            <h1 id="page_title">Settings</h1>
            <div
              style={{ width: '20px', marginTop: '4rem', marginLeft: '2rem' }}
            >
              <Badge size="md" variant="filled">{`v.${getVersion()}`}</Badge>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="w60">
                <StatusTable
                  auth={authState}
                  title="API status"
                  url={`${Base_url}/helth`}
                  name="API"
                />
                <StatusTable
                  auth={authState}
                  title="Bandit status"
                  url={`${url}/helth`}
                  name="Bandits"
                />
                <Card>
                  <p className="message title">
                    {getDevMessage.dev_message?.type.toUpperCase()} LOG:
                  </p>
                  <p className="message">{getDevMessage.dev_message?.title}</p>
                  <p className="message">
                    {getDevMessage.dev_message?.message}
                  </p>
                </Card>
              </div>
              <div style={{ width: '700px' }}>
                <Card width="auto">
                  <iframe
                    title="status"
                    src={`${Base_url}/status`}
                    height="1000px"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
