import React from 'react';
import { ScrollArea, Table } from '@mantine/core';
import { nanoid } from 'components/utils/utils';
import {
  desimalToPercent,
  numberFormate,
} from 'components/pages/statistics/utils';
import { formatId } from './Utils';
import { Segments } from '../Teaser.types';

type Props = {
  data: Segments[];
};

const LiveStatTable = ({ data }: Props) => {
  const rows = (data || []).map((segment: any) => {
    return (
      <tr key={nanoid()}>
        <td>{formatId(segment.id)}</td>
        <td>{numberFormate(segment.impressions)}</td>
        <td>{numberFormate(segment.clicks)}</td>
        <td>{desimalToPercent(segment.CTR)}</td>
      </tr>
    );
  });

  return (
    <ScrollArea sx={{ height: 500 }}>
      <Table verticalSpacing="xs" horizontalSpacing={'lg'}>
        <thead>
          <tr>
            <th>Segment</th>
            <th style={{ textAlign: 'center' }}>Impressions</th>
            <th style={{ textAlign: 'center' }}>Clicks</th>
            <th style={{ textAlign: 'center' }}>CTR</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
};

export default LiveStatTable;
