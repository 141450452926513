import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

import '../styles/Sidebar.scss';

type Props = {
  nav: any;
  isActive: (path: string) => boolean;
};

const NavigationCollapsed = ({ nav, isActive }: Props) => {
  return (
    <>
      <li
        className="nav_row--collapse"
        id={isActive(nav.name) ? 'active_nav' : ''}
      >
        {nav.icon ? (
          <Tooltip label={nav.name} position="right" transition="slide-right">
            <NavLink id="title" className="navlink" to={nav.path}>
              <img id="icon" src={(nav as any).icon} alt={(nav as any).name} />
            </NavLink>
          </Tooltip>
        ) : (
          <span style={{ marginLeft: '6.5rem' }} />
        )}
      </li>
    </>
  );
};

export default React.memo(NavigationCollapsed);
