import { ActionTypes } from 'actions/statsDataAction';

type State = {
  statsData: any;
  isLoading: boolean;
  searchString: string;
};

const initSate: State = {
  statsData: null,
  isLoading: false,
  searchString: '',
};

function statsDataReducer(state = initSate, action: ActionTypes) {
  switch (action.type) {
    case 'SET_STATS_DATA':
      return {
        ...state,
        statsData: action.payload,
        isLoading: action.loading,
        searchString: action.searchString,
      };
    default:
      return state;
  }
}

export default statsDataReducer;
