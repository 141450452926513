import { useEffect, useCallback } from 'react';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import useStateIfMounted from './useStateIfMounted';

export const useUserRoles = (oktaAuth: OktaAuth, authState: AuthState) => {
  const [roles, setRoles] = useStateIfMounted<string[] | null>(null);

  const getUserData = useCallback(async () => {
    if (authState?.isAuthenticated) {
      await oktaAuth.getUser().then((info: any) => {
        setRoles(info?.groups);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserData().catch(console.error);
  }, [getUserData]);

  // return the current user roles
  return roles;
};
