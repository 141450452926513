import React, { useCallback, useEffect, useRef } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig, oktaSignInConfig } from './components/auth/oktaConfig';
import { Base_url, nanoid } from './components/utils/utils';
import { notification } from 'components/utils/Notification';
import { datadogLogs } from '@datadog/browser-logs';
import { useAppDispatch } from 'components/utils/hooks/useRedux';

import Dashboard from './components/pages/Dashboard';
import Login from './components/pages/Login';
import NotFound from './components/pages/NotFound';
import { NavLinks } from './components/utils/NavLinks';
import Home from 'components/pages/Home';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const App = () => {
  const history = useHistory();
  const evtSrc = useRef<any>(null);
  const dispatch = useAppDispatch();

  const listenToEvents = useCallback(() => {
    if (!evtSrc.current) {
      evtSrc.current = new EventSource(`${Base_url}/sse/events/messages`);

      evtSrc.current.addEventListener('open', () => {
        if (process.env.NODE_ENV !== 'production') {
          console.info('SSE connected');
        } else {
          const id: string = nanoid();
          datadogLogs.logger.info(`SSE connected: ${id}`, {
            name: 'SSE',
            id: id,
            msg: `SSE connected with id: ${id}`,
          });
        }
      });

      evtSrc.current.addEventListener('message', (e: any) => {
        const data: any = JSON.parse(e.data);
        if (e.data !== '{}' && data?.type !== 'development') {
          notification(data);
        } else if (e.data !== '{}' && data?.type === 'development') {
          dispatch({ type: 'SET_DEV_MESSAGE', payload: data });
        }
      });

      evtSrc.current.addEventListener('error', (e: any) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error: ', e);
        }
        datadogLogs.logger.error(`Error: ${e}`, {
          name: 'Error',
          id: nanoid(),
          error_msg: e,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth: any, originalUri: any) => {
      history.replace(
        toRelativeUrl(originalUri || '/', window.location.origin),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const navlist: JSX.Element[] = NavLinks.map((nav: any, key: number) => {
    return (
      <SecureRoute key={key} exact path={nav.path} component={nav.component} />
    );
  });

  useEffect(() => {
    listenToEvents();

    return () => {
      evtSrc.current.close();
    };
  }, [listenToEvents]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <SecureRoute path="/dashboard" component={Dashboard} />
        {navlist}
        <Route path="/login/callback" component={LoginCallback} />
        <Route
          path="/sign-in"
          exact={true}
          render={() => <Login config={oktaSignInConfig} />}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Security>
  );
};

export default withRouter(App);
