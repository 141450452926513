import React from 'react';
import { Badge, Card, Group, Text } from '@mantine/core';
import { desimalToPercent, numberFormate } from '../utils';
import {
  Base_url,
  capitalizeFirstLetter,
  limitString,
  nanoid,
} from 'components/utils/utils';
import WrapperCard from 'components/Card';
import { useStyles } from './useStyles.theme';
import Img from 'components/image/Image';

import dummyImg from './assets/dummy.png';
import variables from 'global.module.scss';

interface StatsGroupProps {
  data: {
    CTR: number;
    clicks: number;
    frontId: string;
    gender_segment: string;
    impressions: number;
  }[];
  articleID: string;
  newsroom: string;
}

const OptimalGroup = ({ data, articleID, newsroom }: StatsGroupProps) => {
  const { classes } = useStyles();

  const stats = data.map((stat: any) => {
    const imgUrl: string = `${Base_url}/report/teaser/2/newsdesk/${newsroom}/${articleID}/${stat?.frontId}`;

    return (
      <Card key={nanoid()} style={{ backgroundColor: variables.white }}>
        <Card.Section className={classes.imageSection}>
          <Img
            width={'100%'}
            placeholderImg={dummyImg}
            src={imgUrl}
            errorImg={dummyImg}
            alt={stat?.frontId}
          />
        </Card.Section>
        <Group mt="sm" position="apart">
          <div>
            <Text weight={500}>
              {`Optimal: ${capitalizeFirstLetter(stat?.gender_segment)}`}
            </Text>
            <Text size="xs" color="dimmed" title={stat?.frontId.toString()}>
              {limitString(stat?.frontId.toString(), 20)}
            </Text>
          </div>
          <Badge variant="outline">{`CTR: ${desimalToPercent(
            stat?.CTR,
          )}`}</Badge>
        </Group>
        <Group>
          <Text size="sm" color="dimmed" className={classes.label}>
            {`Clicks: ${numberFormate(stat?.clicks)}`}
          </Text>
          <Text size="sm" color="dimmed" className={classes.label}>
            {`Impressions: ${numberFormate(stat?.impressions)}`}
          </Text>
        </Group>
      </Card>
    );
  });

  return (
    <>
      {data.length > 0 ? (
        <WrapperCard>
          <div style={{ display: 'flex' }}>{stats}</div>
        </WrapperCard>
      ) : (
        <p className="no-content">No content to load</p>
      )}
    </>
  );
};

export default OptimalGroup;
