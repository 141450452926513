import React, { useEffect, useState } from 'react';
import Card from '../Card';
import useFetch from '../utils/hooks/useFetch';
import { AuthState } from '@okta/okta-auth-js';
import { useAppDispatch } from '../utils/hooks/useRedux';
import { Base_url, fetchOptions, nanoid } from '../utils/utils';
import { Group, Menu, Radio, RadioGroup, Switch, Tooltip } from '@mantine/core';
import Teasers from './Teasers';
import BanditModal from './banditsModal/Bandits.modal';
import { notification } from 'components/utils/Notification';

import './styles/Teasers.scss';

type Props = {
  articleId: string;
  auth: AuthState;
  site: string;
  dispatchTarget:
    | 'SET_ARTICLE_ID_VG'
    | 'SET_ARTICLE_ID_E24'
    | 'SET_ARTICLE_ID_STORBY'
    | 'SET_ARTICLE_ID_AFFILIATE';
  isChecked: boolean;
};

const TeasersCard = ({
  articleId,
  auth,
  site,
  dispatchTarget,
  isChecked,
}: Props) => {
  const [filterValue, setFilterValue] = useState<string>('live');
  const [switchChecked, setChecked] = useState<boolean | undefined>(isChecked);
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);

  let options = fetchOptions(auth);

  const articleIdEncode: string = encodeURIComponent(articleId);

  const { isLoading, data } = useFetch(
    `${Base_url}/articles/live/2/${site}/${articleIdEncode}`,
    options,
  );

  useEffect(() => {
    if (data?.isOptimizable === true) {
      setChecked(isChecked);
    } else {
      setChecked(false);
    }
  }, [data?.isOptimizable, isChecked]);

  // Filter teaser list to only live = true
  const filterisLive = (arr: Array<any>): any[] => {
    return arr?.filter((teaser: any) => {
      return teaser?.isLive === true && teaser?.clicks > 0;
    });
  };

  // Filter teaser list to only live = false
  const filterNotLive = (arr: Array<any>): any[] => {
    return arr?.filter((teaser: any) => {
      return teaser?.isLive === false && teaser?.clicks > 0;
    });
  };

  // Object combining all filter arrays to one object
  const filters: any = {
    live: filterisLive(data?.teasers),
    notLive: filterNotLive(data?.teasers),
  };

  // Puts article id + article object in redux stor on page load
  useEffect(() => {
    if (!isLoading && Object.keys(data).length !== 0) {
      dispatch({
        type: dispatchTarget,
        payload: { articleId: articleId, articleObject: data },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, articleId, isLoading]);

  // Stops the running bandit by Deleteing the ReJSON from the DB
  const stopBandit = async () => {
    try {
      await fetch(`${Base_url}/bandits/stop/${site}_${articleIdEncode}`, {
        method: 'DELETE',
        headers: options?.headers,
      }).then(() => {
        notification({
          id: nanoid(),
          message: `The bandit for ${articleId} has successfully stopped on ${site}`,
          title: `Stopped bandit: ${articleId}`,
          type: 'info',
          autoClose: false,
          loading: false,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitch = (event: any) => {
    setChecked(event.currentTarget.checked);
    if (switchChecked === false) {
      setOpened(true);
    } else {
      stopBandit();
    }
  };

  const getFilterValue: string =
    filterValue === 'notLive' ? 'Not live' : 'Live';

  return (
    <div>
      {!isLoading ? (
        <div>
          <Card overFlowY="hidden" id={articleId} height="auto">
            <Group position="apart" mt={-10}>
              <h2
                title={
                  filterValue !== 'notLive'
                    ? `Updated: ${data?.updated}`
                    : undefined
                }
                style={{ color: '#697178' }}
              >{`${decodeURIComponent(articleId)} | ${getFilterValue}`}</h2>
              <Group position="right">
                <Tooltip
                  label={
                    data?.isOptimizable
                      ? 'Start/Stop bandit'
                      : 'Article is not optimizable'
                  }
                  withArrow
                >
                  <Switch
                    disabled={!data?.isOptimizable}
                    aria-label="Bandit switch"
                    onChange={(event) => handleSwitch(event)}
                    checked={switchChecked}
                  />
                </Tooltip>
                <Menu placement="end">
                  <Menu.Label mb={2}>Teasers filter</Menu.Label>
                  <Group align="flex-end" p={2} ml={8} mb={2}>
                    <RadioGroup
                      value={filterValue}
                      size="sm"
                      onChange={setFilterValue}
                      orientation="vertical"
                      required
                    >
                      <Radio value="live" label="Show live teasers only" />
                      <Radio value="notLive" label="Show historical" />
                    </RadioGroup>
                  </Group>
                </Menu>
              </Group>
            </Group>
            <Teasers
              filter={filters[filterValue]}
              filterType={filterValue}
              totalImps={data?.statistics?.impressions}
            />
          </Card>
          <BanditModal
            opened={opened}
            setOpened={setOpened}
            articleID={articleId}
            setChecked={setChecked}
            auth={auth}
            site={site}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TeasersCard;
