import { useEffect, useState } from 'react';

const usePersistedState = (key: string, initialState: any) => {
  const [state, setState] = useState(
    localStorage.getItem(key) !== undefined
      ? JSON.parse(localStorage.getItem(key) as string)
      : initialState,
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
};

export default usePersistedState;
