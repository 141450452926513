import { createAction } from '@reduxjs/toolkit';

export const setStorbyValue = createAction<string>('SET_DROPDOWN_STORBY');
export const setStatisticsValue = createAction<string>(
  'SET_DROPDOWN_STATISTICS',
);
export const resetValueToDefault = createAction('RESET_DROPDOWN');
export const setVgTeaserId = createAction<string>('SET_DROPDOWN_VG_TEASER_ID');
export const setStorbyTeaserId = createAction<string>(
  'SET_DROPDOWN_STORBY_TEASER_ID',
);
export const setE24TeaserId = createAction<string>(
  'SET_DROPDOWN_E24_TEASER_ID',
);
export const setAffiliateTeaserId = createAction<string>(
  'SET_DROPDOWN_AFFILIATE_TEASER_ID',
);

export type ActionTypes =
  | { type: typeof setStorbyValue; payload: string }
  | { type: typeof setStatisticsValue; payload: string }
  | { type: typeof setVgTeaserId; payload: string }
  | { type: typeof setStorbyTeaserId; payload: string }
  | { type: typeof setE24TeaserId; payload: string }
  | { type: typeof setAffiliateTeaserId; payload: string }
  | { type: typeof resetValueToDefault };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setStatisticsValue,
  setStorbyValue,
  resetValueToDefault,
  setVgTeaserId,
  setStorbyTeaserId,
  setE24TeaserId,
  setAffiliateTeaserId,
};
