/* eslint-disable */
import trendingUp from '../assets/icons/trendingUp.svg';
import dashboard from '../assets/icons/dashboard.svg';
import settings from '../assets/icons/settings.svg';
import home from '../assets/icons/home.svg';
import dashboardV2 from '../assets/icons/dashboardV2.svg';
import logout from '../assets/icons/logoutLight.svg';
import search from '../assets/icons/search.svg';

import Statistics from '../pages/statistics/Statistics';
import Settings from '../pages/settings/Settings';
import Bandits from '../pages/bandits/Bandits';
import Search from '../pages/search/Search';

/*
* Update this array to add a new page to the sidebar.
* Structure:
{
  name: "Pagename",
  path: "path to page",
  icon: svg,
  component: Component,
  sub?: [{...}], 
}
*/

export const NavLinks: Array<Object> = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: home,
    /* sub: [
      { url: "/vg", icon: dashboard, name: "VG" },
      { url: "/storby", icon: dashboard, name: "Storby" },
    ], */
  },
  {
    name: 'VG',
    path: '/dashboard/vg',
    icon: dashboard,
  },
  {
    name: 'E24',
    path: '/dashboard/e24',
    icon: dashboard,
  },
  {
    name: 'Storby',
    path: '/dashboard/storby',
    icon: dashboard,
  },
  {
    name: 'Affiliate',
    path: '/dashboard/affiliate',
    icon: dashboard,
  },
  {
    name: 'Teaser search',
    path: '/teasers',
    icon: search,
    component: Search,
  },
  {
    name: 'Statistics',
    path: '/statistics',
    icon: trendingUp,
    component: Statistics,
  },
  {
    name: 'Bandits',
    path: '/bandits',
    icon: dashboardV2,
    component: Bandits,
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: settings,
    component: Settings,
  },
  /* {
    name: 'Logout',
    path: '/settings',
    icon: logout,
  }, */
];
