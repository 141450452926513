import React from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import OktaSignInWidget from '../auth/OktaSignInWidget';
import { Tokens } from '@okta/okta-auth-js';

type Props = {
  config: Object;
};

const Login: React.FC<Props> = (props) => {
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens: Tokens | undefined) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err: string) => {
    console.log('error logging in', err);
  };

  if (!authState) return null;

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/dashboard' }} />
  ) : (
    <OktaSignInWidget
      config={props.config}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};
export default Login;
