import React, { ReactNode } from 'react';
import { useUserRoles } from 'components/utils/hooks/useUserRole';
import { useOktaAuth } from '@okta/okta-react';
import { AuthState } from '@okta/okta-auth-js';
import { LoadingOverlay } from '@mantine/core';

type Props = {
  roles: string | string[];
  children: ReactNode;
  errorPage?: ReactNode;
};

const RolesAuthRoute = ({ roles, children, errorPage }: Props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const userRoles = useUserRoles(oktaAuth, authState as AuthState);

  const canAccess = userRoles?.some((userRole) => roles.includes(userRole));

  if (!authState) return null;

  if (canAccess) return <>{children}</>;

  if (userRoles === null)
    return (
      <div className="pg_wrapper">
        <LoadingOverlay visible={true} />
      </div>
    );

  return (
    <div className="pg_wrapper">
      {errorPage ? (
        errorPage
      ) : (
        <h1 id="page_title">
          You are not authorized to see the content of this page!
        </h1>
      )}
    </div>
  );
};

export default RolesAuthRoute;
