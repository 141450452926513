import React from 'react';
import Spots from '../../front/Spots';
import { useAppSelector } from 'components/utils/hooks/useRedux';
import { AuthState } from '@okta/okta-auth-js';
import { forceReload } from 'components/front/utils';
import Toolbar from 'components/toolbar/Toolbar';

import './styles/dashboard.vg.scss';

type Props = {
  auth: AuthState;
};

const VG = ({ auth }: Props) => {
  const getArticleIDs = useAppSelector((state) => state.articleidreducer);

  return (
    <div className="subpg_wrapper">
      <Toolbar
        articleIds={getArticleIDs.articleIdsVG}
        action={forceReload}
        dispatchTarget={'SET_DROPDOWN_VG_TEASER_ID'}
      />
      <Spots auth={auth} site="vg" dispatchTarget="SET_ARTICLE_ID_VG" />
    </div>
  );
};

export default VG;
