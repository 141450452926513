/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useOktaAuth, SecureRoute } from '@okta/okta-react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Header from '../Header';
import Sidebar from '../Sidebar';
import SiteOverview from 'components/overview/Overview';
import Dashboard_Storby from './subpages/Dashboard.storby';
import Dashboard_VG from './subpages/Dashboard.vg';
import Dashboard_E24 from './subpages/Dashboard.e24';
import Dashboard_Affiliate from './subpages/Dashboard.affiliate';
import NotFound from './NotFound';
import usePersistedState from 'components/utils/hooks/usePersistedState';

import './styles/Dashboard.scss';
import RolesAuthRoute from 'components/auth/RoleRouteAuth';

const Dashboard: React.FC = () => {
  // eslint-disable-next-line
  const { oktaAuth, authState } = useOktaAuth();
  const [isClosed, toggle] = usePersistedState('Sidemenu', false);

  let { path } = useRouteMatch();

  if (!authState) return null;

  return (
    <div
      className="parent"
      style={isClosed ? { gridTemplateColumns: '5rem' } : {}}
    >
      <div className="sideBar_container">
        <Sidebar
          toggle={isClosed}
          handleClick={() => {
            toggle(!isClosed);
          }}
        />
      </div>
      <div className="dashboard_container">
        <Header />
        <Switch>
          <SecureRoute exact path={path}>
            <div className="pg_wrapper overview">
              <SiteOverview title="VG overview" />
              <SiteOverview title="E24 overview" />
              <SiteOverview title="Storby overview" />
              <SiteOverview title="Affiliate overview" />
            </div>
          </SecureRoute>
          <SecureRoute exact path={`${path}/vg`}>
            <RolesAuthRoute roles={'MAB-admin'}>
              <Dashboard_VG auth={authState} />
            </RolesAuthRoute>
          </SecureRoute>
          <SecureRoute exact path={[`${path}/storby`]}>
            <RolesAuthRoute roles={'MAB-admin'}>
              <Dashboard_Storby auth={authState} />
            </RolesAuthRoute>
          </SecureRoute>
          <SecureRoute exact path={[`${path}/e24`]}>
            <RolesAuthRoute roles={'MAB-admin'}>
              <Dashboard_E24 auth={authState} />
            </RolesAuthRoute>
          </SecureRoute>
          <SecureRoute exact path={[`${path}/affiliate`]}>
            <RolesAuthRoute roles={'MAB-admin'}>
              <Dashboard_Affiliate auth={authState} />
            </RolesAuthRoute>
          </SecureRoute>
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
