import React, { useEffect, useState } from 'react';
import { desimalToPercent, isoFormate } from '../utils';
import { limitString, nanoid } from 'components/utils/utils';
import { sortData } from './utils';
import Modal from '../teaserModal/Modal';
import Th from './Th';

import './Table.scss';
import { ScrollArea } from '@mantine/core';

type Props = {
  title?: string;
  data: Array<any>;
  headers: Array<object>;
};

const Table = ({ title, data, headers }: Props) => {
  const [opened, setOpened] = useState(false);
  const [currentTeaser, setCurrentTeaser] = useState<any>({});
  const [sortedData, setSortedData] = useState<Array<any>>(data);
  const [sortBy, setSortBy] = useState<keyof any>();
  const [reverseSortDirection, setReverseSortDirection] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const setSorting = (field: keyof string): void => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed }));
  };

  const renderTableHeader = (): JSX.Element[] => {
    return headers.map((header: any) => {
      return (
        <Th
          key={nanoid()}
          sorted={sortBy === header.name}
          reversed={reverseSortDirection}
          onSort={() => setSorting(header.name)}
          allowSort={header.sort}
        >
          {header.name.toUpperCase()}
        </Th>
      );
    });
  };

  const handelOnClick = (row: Object): void => {
    setCurrentTeaser(row);
    setOpened(true);
  };

  const renderTableData = (): JSX.Element[] => {
    return sortedData.map((row: any) => {
      return (
        <tr key={nanoid()} onClick={() => handelOnClick(row)}>
          <td title={row.teaser || row.variant || row.teaserId}>
            {limitString(row.teaser || row.variant || row.teaserId, 20)}
          </td>
          <td>{limitString(row.version, 10)}</td>
          <td>{row.impressions}</td>
          <td>{row.clicks}</td>
          <td>{isoFormate(row.firstAppearanceReadable)}</td>
          <td>{desimalToPercent(row.CTR)}</td>
        </tr>
      );
    });
  };

  return (
    <div className="table">
      <h1 className="table_title">{title}</h1>
      <ScrollArea
        sx={{ height: 450 }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <table id="table" cellSpacing={0}>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>{data.length > 0 ? renderTableData() : null}</tbody>
        </table>
        {data.length === 0 ? (
          <p className="no-content">No content to load</p>
        ) : null}
      </ScrollArea>
      <Modal
        html={currentTeaser.apiHtml}
        css={currentTeaser.css}
        cssSource={currentTeaser.cssSource}
        frontTool={currentTeaser.frontTool}
        id={currentTeaser.id}
        isOpen={opened}
        isClosed={() => {
          setOpened(false);
        }}
      />
    </div>
  );
};

export default Table;
