import React, { useEffect } from 'react';
import { getTeaserHtml } from 'components/front/utils';
import { mountCorrectStyle } from '../utils';

import noContent from 'components/assets/images/undraw_no_content.svg';
import './Modal.scss';

type Props = {
  html: string;
  id: string;
  css: string;
  cssSource: string;
  frontTool: string;
};

const ModalTeaser = ({ html, id, css, cssSource, frontTool }: Props) => {
  useEffect(() => {
    if ((cssSource || css) && frontTool) {
      mountCorrectStyle(cssSource, css, frontTool);
    }
  }, [css, cssSource, frontTool]);

  return (
    <>
      {html !== '' && html ? (
        <div className="teaser" id={id}>
          {getTeaserHtml(html)}
        </div>
      ) : (
        <div className="no-teaser">
          <img src={noContent} width="25%" alt="No content found"></img>
          <p className="no-content">No teaser found</p>
        </div>
      )}
    </>
  );
};

export default ModalTeaser;
