import React from 'react';
import Card from 'components/Card';
import { Accordion } from '@mantine/core';

type Props = {
  title: string;
  height?: string;
};

const SiteOverview = ({ title, height = '600px' }: Props) => {
  return (
    <div>
      <Accordion initialItem={undefined}>
        <Accordion.Item label={title}>
          <Card height={height}>
            <div />
          </Card>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SiteOverview;
