import React from 'react';
import { AuthState } from '@okta/okta-auth-js';
import Spots from 'components/front/Spots';
import { forceReload } from 'components/front/utils';
import { useAppSelector } from 'components/utils/hooks/useRedux';
import Toolbar from 'components/toolbar/Toolbar';

import './styles/dashboard.e24.scss';

type Props = {
  auth: AuthState;
};

const Affiliate = ({ auth }: Props) => {
  const getArticleIDs = useAppSelector((state) => state.articleidreducer);

  return (
    <div className="subpg_wrapper">
      <Toolbar
        articleIds={getArticleIDs.articleIdsAffiliate}
        action={forceReload}
        dispatchTarget={'SET_DROPDOWN_AFFILIATE_TEASER_ID'}
      />
      <Spots
        auth={auth}
        site="affiliate"
        dispatchTarget="SET_ARTICLE_ID_AFFILIATE"
      />
    </div>
  );
};

export default Affiliate;
