import React, { useEffect, useState } from 'react';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import { Base_url, fetchOptions } from 'components/utils/utils';
import { useOktaAuth } from '@okta/okta-react';
import { useAppSelector } from 'components/utils/hooks/useRedux';
import StatSection from './Stat.section';
import usePersistedState from 'components/utils/hooks/usePersistedState';

import './Bandits.scss';

const Bandits = () => {
  const [isClosed, toggle] = usePersistedState('Sidemenu', false);
  const [newsroomData, setNewsroomData] = useState<any>();
  const getArticles = useAppSelector((state) => state.articleidreducer);

  useEffect(() => {
    fetchNewsrooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { authState } = useOktaAuth();
  if (!authState) return null;

  let options = fetchOptions(authState);

  const fetchNewsrooms = () => {
    Promise.all([
      fetch(`${Base_url}/articles/live/2/vg`, options),
      fetch(`${Base_url}/articles/live/2/e24`, options),
      fetch(`${Base_url}/articles/live/2/storby`, options),
      fetch(`${Base_url}/articles/live/2/affiliate`, options),
    ])
      .then(([res1, res2, res3, res4]) =>
        Promise.all([res1.json(), res2.json(), res3.json(), res4.json()]),
      )
      .then(([data1, data2, data3, data4]) =>
        setNewsroomData({
          vgData: data1,
          e24Data: data2,
          storbyData: data3,
          affiliateData: data4,
        }),
      );
  };

  return (
    <div
      className="parent"
      style={isClosed ? { gridTemplateColumns: '5rem' } : {}}
    >
      <div className="sideBar_container">
        <Sidebar
          toggle={isClosed}
          handleClick={() => {
            toggle(!isClosed);
          }}
        />
      </div>
      <div className="dashboard_container">
        <Header />
        <div className="pg_wrapper">
          <div className="bandits">
            <StatSection
              label="VG"
              data={newsroomData?.vgData}
              articleData={getArticles?.articleIdsVG}
            />
            <StatSection
              label="E24"
              data={newsroomData?.e24Data}
              articleData={getArticles?.articleIdsE24}
            />
            <StatSection
              label="Storby"
              data={newsroomData?.storbyData}
              articleData={getArticles?.articleIdsStorby}
            />
            <StatSection
              label="Affiliate"
              data={newsroomData?.affiliateData}
              articleData={getArticles?.articleIdsAffiliate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bandits;
