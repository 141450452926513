import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

type Props = {
  config: Object;
  onSuccess: any;
  onError: any;
};

const OktaSignInWidget: React.FC<Props> = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect((): any => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
