import React from 'react';

import './styles/NotFound.scss';
import notFound from 'components/assets/images/undraw_page_not_found.svg';

const NotFound: React.FC = () => {
  return (
    <div className="notFound_container">
      {notFound ? (
        <img src={notFound} alt="404: Not found"></img>
      ) : (
        <h1>404</h1>
      )}
      <h2>Page not found!</h2>
      <br />
      <p>
        The page you are looking for doesn't exist or another error occurred.
      </p>
    </div>
  );
};

export default NotFound;
