import { Accordion } from '@mantine/core';
import React from 'react';
import BanditStat from './banditStat/Bandit.stat';
import BanditTable from './banditTable/Bandit.table';

type Props = {
  data: {
    live_ids: Array<string>;
  };
  articleData?: any;
  label: string;
};

const StatSection = ({ data, articleData, label }: Props) => {
  return (
    <div>
      <Accordion initialItem={0}>
        <Accordion.Item label={label}>
          {data ? (
            <div className="statistics_section">
              <BanditTable data={data} articleData={articleData} />
              <BanditStat data={data} articleData={articleData} />
            </div>
          ) : null}
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default StatSection;
