import React from 'react';
import Card from 'components/Card';
import Segments from './Segments';

import variables from 'global.module.scss';

type SegmentsProps = {
  data: {
    gender_segment: string;
    count: number;
    clicks: number;
    CTR: number;
  }[];
};

const GenderSegment = ({ data }: SegmentsProps) => {
  return (
    <Card>
      <Segments
        colors={[
          variables.stats_green,
          variables.stats_blue,
          variables.stats_gray,
        ]}
        data={data}
      />
    </Card>
  );
};

export default GenderSegment;
