import React from 'react';
import { iconProps } from './iconTypes';

const Logout = ({ color, viewBox = '0 0 24 24', size }: iconProps) => {
  return (
    <svg
      fill={color}
      viewBox={viewBox}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17 8l-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4-4-4zM5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5z" />
    </svg>
  );
};

export default Logout;
