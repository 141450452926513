import React from 'react';
import { TeaserObject } from 'components/front/Teaser.types';
import Card from 'components/Card';
import { Table } from '@mantine/core';
import { nanoid } from '@reduxjs/toolkit';
import ModalTeaser from '../statistics/teaserModal/Modal.teaser';
import { desimalToPercent, numberFormate } from '../statistics/utils';

type Props = {
  data: Array<TeaserObject>;
  newsroom?: string;
  articleID?: string;
};

const TeaserTable = ({ data }: Props) => {
  const sortTeaserFilter = (): any => {
    return data?.sort((a: any, b: any) => {
      return b['CTR'] - a['CTR'];
    });
  };

  const rows = (sortTeaserFilter() || []).map((teaser: any) => {
    return (
      <tr key={nanoid()}>
        <td style={{ width: '500px' }}>
          {
            <div
              style={{
                marginBottom: '2rem',
                marginRight: '4rem',
                marginTop: '2rem',
              }}
            >
              <ModalTeaser
                html={teaser?.apiHtml}
                id={teaser?.id}
                css={teaser?.css}
                cssSource={teaser?.cssSource}
                frontTool={teaser?.frontTool}
              />
            </div>
          }
        </td>
        <td>
          <h1 style={{ color: '#495057' }}>
            {numberFormate(teaser?.clicks || 0)}
          </h1>
        </td>
        <td>
          <h1 style={{ color: '#495057' }}>
            {numberFormate(teaser?.impressions || 0)}
          </h1>
        </td>
        <td>
          <h1 style={{ color: '#495057' }}>
            {desimalToPercent(teaser?.CTR || 0)}
          </h1>
        </td>
      </tr>
    );
  });

  return (
    <Card>
      <Table verticalSpacing="xs">
        <thead>
          <tr>
            <th style={{ fontSize: '2rem' }}>Teaser</th>
            <th style={{ fontSize: '2rem' }}>Clicks</th>
            <th style={{ fontSize: '2rem' }}>Impressions</th>
            <th style={{ fontSize: '2rem' }}>CTR</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Card>
  );
};

export default TeaserTable;
