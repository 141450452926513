import React from 'react';
import { Group, Avatar, Menu, Text, Divider } from '@mantine/core';
import UserButton from './UserButton';

import variables from 'global.module.scss';
import LogoutIcon from 'components/assets/icons/component_icons/logout';
import SettingsIcon from 'components/assets/icons/component_icons/settings';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from 'components/utils/utils';

type UserAccountProps = {
  userInfo: any;
  logout: () => Promise<void>;
};

const UserAccount = ({ userInfo, logout }: UserAccountProps) => {
  const getNameFromEmail = (email: string): string => {
    if (userInfo) {
      const names: Array<string> = email.split('@')[0].split('.');
      return names.map((name) => capitalizeFirstLetter(name)).join(' ');
    }
    return '';
  };

  const getAcronymFromEmail = (email: string): string => {
    if (userInfo) {
      return email.charAt(0).toUpperCase();
    }
    return '';
  };

  return (
    <Group position="center">
      <Menu
        withArrow
        size="auto"
        placement="start"
        control={<UserButton acronym={getAcronymFromEmail(userInfo?.email)} />}
      >
        <Avatar
          size={80}
          radius={120}
          mx="auto"
          mt="xl"
          styles={{
            placeholder: {
              color: variables.prime_dark,
              backgroundColor: variables.site_bg,
              fontWeight: 'normal',
            },
          }}
        >
          {getAcronymFromEmail(userInfo?.email)}
        </Avatar>
        <Text align="center" size="sm" weight={500} mt="md">
          {getNameFromEmail(userInfo?.email)}
        </Text>
        <Text align="center" mx="lg" color="dimmed" size="xs">
          {userInfo?.email}
        </Text>
        <Divider />
        <Menu.Label mt="md">Profile</Menu.Label>
        <Menu.Item
          icon={<SettingsIcon size={14} color={variables.prime_dark} />}
          component={Link}
          to="/settings"
        >
          Settings
        </Menu.Item>
        <Menu.Item
          icon={<LogoutIcon size={14} color={variables.prime_dark} />}
          onClick={() => logout()}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Group>
  );
};

export default React.memo(UserAccount);
