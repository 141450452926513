import React from 'react';

import './styles/Card.scss';

type Props = {
  children: React.ReactNode;
  width?: string;
  height?: string;
  id?: string;
  marginTop?: string;
  shadow?: boolean;
  overFlowX?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
  overFlowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
};

const Card = ({
  children,
  width = 'auto',
  height = 'auto',
  id,
  marginTop = '5rem',
  shadow,
  overFlowX = 'hidden',
  overFlowY = 'hidden',
}: Props) => {
  return (
    <div
      className="card_wrapper"
      id={id}
      style={{
        width: width,
        height: height,
        marginTop: marginTop,
        boxShadow: shadow
          ? '0 7px 30px -10px rgba(150, 170, 180, 0.5)'
          : 'none',
        overflowX: overFlowX,
        overflowY: overFlowY,
      }}
    >
      {children}
    </div>
  );
};

export default Card;
