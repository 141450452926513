import React from 'react';
import { Group, Menu, Radio, RadioGroup } from '@mantine/core';

type Props = {
  opened: boolean | undefined;
  open: () => void;
  close: () => void;
  value: string | undefined;
  setValue: (value: string) => void;
};

const filterMenu = ({ opened, open, close, value, setValue }: Props) => {
  return (
    <>
      <Menu opened={opened} onOpen={open} onClose={close}>
        <Menu.Label>Filter</Menu.Label>
        <Group align="flex-end" spacing="xs" p={2} ml={8} mb={2}>
          <RadioGroup
            value={value}
            size="sm"
            onChange={setValue}
            orientation="vertical"
            required
          >
            <Radio value="impressions" label="Impressions" />
            <Radio value="clicks" label="Clicks" />
          </RadioGroup>
        </Group>
      </Menu>
    </>
  );
};

export default React.memo(filterMenu);
