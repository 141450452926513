import moment from 'moment';

// Exported const:
/*
 * Matching Revision id (-<REVISION-ID>) from a given dr. Front ID: df-xx-xxxxxxxx-<REVISION-ID>
 * @type RegExp
 * @returns boolean
 */
export const REVISION_PATTERN: RegExp = /(-[0-9]{5})/;

/*
 * Matching Dr. Front id (df-xx-xxxxxxxx-) from a given dr. Front ID: df-xx-xxxxxxxx-<REVISION-ID>
 * @type RegExp
 * @returns boolean
 */
export const DF_PATTERN: RegExp = /([df]{2}-[0-9]{1,2}-[a-zA-Z-0-9]{8}-)/;

// Formate number with space as decimal number separator
export const numberFormate = (number: number): string => {
  return new Intl.NumberFormat('no-NO').format(number);
};

// Formate desimal to %
export const desimalToPercent = (
  desimal: number | undefined,
  minFracDigits: number = 2,
): string | undefined => {
  return desimal?.toLocaleString('en', {
    style: 'percent',
    minimumFractionDigits: minFracDigits,
  });
};

// Formate ISO string to date/time formate
export const isoFormate = (
  iso: string,
  format: string = 'DD.MM.YYYY / HH:mm:ss',
): string => {
  const pattern: RegExp = /(\d{4}-\d{2}-\d{2}T[0-2]\d:[0-5]\d:[0-5]\d.\d{0,}Z)/;
  if (pattern.test(iso)) {
    return moment(iso).format(format);
  }
  return iso;
};

export const createLinkTag = (id: string, cssSource: string): void => {
  if (cssSource !== undefined) {
    const linkTag: HTMLLinkElement = document.createElement('link');
    linkTag.type = 'text/css';
    linkTag.rel = 'stylesheet';
    linkTag.setAttribute('id', id);
    document.head.appendChild(linkTag);
    linkTag.href = cssSource;
  }
};

export const mountStyleLink = (source: string, tagId: string): void => {
  const link: HTMLElement | null = document.getElementById(tagId);
  if (link === null) {
    createLinkTag(tagId, source);
  }
};

const createStyleTag = (id: string, css: string): void => {
  const styleTag: HTMLStyleElement = document.createElement('style');
  styleTag.setAttribute('id', id);
  styleTag.textContent = css;
  document.head.appendChild(styleTag);
};

export const mountStyles = (stylesStr: string, tagId: string): void => {
  const dfStyle: HTMLElement | null = document.getElementById(tagId);
  const newString = stylesStr
    ? stylesStr.replace(
        '@import url("https://vgpartnerstudio.drfront.aptoma.no/skin/css?id=3");',
        '',
      )
    : '';
  if ((dfStyle === null && newString !== '') || dfStyle?.textContent === '') {
    createStyleTag(tagId, newString);
  }
};

export const unMountStyle = (tagId: string): void => {
  const styleTag: HTMLElement | null = document.getElementById(tagId);
  if (styleTag !== null) {
    styleTag.remove();
  }
};

export const mountCorrectStyle = (
  cssSource: string,
  cssString: string,
  frontTool: string,
) => {
  if (cssSource && cssSource.includes('content-studio.inventory')) {
    mountStyleLink(
      cssSource,
      `${frontTool}-style-${cssSource
        .replace('https://content-studio.inventory.schibsted.io/css/', '')
        .replace('/styles.css', '')}`,
    );
  } else if (cssSource && cssSource.includes('drfront.css')) {
    mountStyleLink(cssSource, `${frontTool}-style`);
  } else if (cssString && cssString !== '') {
    mountStyles(cssString, `${frontTool}-style`);
  } else {
    mountStyleLink(cssSource, `${frontTool}-style`);
  }
};

// Calculating the max domain so both charts follow the same max.
export const calcDomainMax = (value: string, arr1: any, arr2: any): number => {
  const maxMale: number = Math.max(...arr1.map((item: any) => item[value]));
  const maxFemale: number = Math.max(...arr2.map((item: any) => item[value]));
  return Math.max(maxMale, maxFemale);
};
