const callbackPath: string =
  process.env.NODE_ENV === 'production' ? '/login/callback' : '/login/callback';

const oktaAuthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  redirectUri: `${window.location.origin}${callbackPath}`,
  scopes: ['openid', 'profile', 'email', 'groups'],
};

const oktaSignInConfig = {
  baseUrl: `https://${process.env.REACT_APP_OKTA_DOMAIN}`,
  logo: 'https://ok1static.oktacdn.com/fs/bco/1/fs01hk481qiEsfMgP0h8',
  clientId: `${process.env.REACT_APP_CLIENT_ID}`,
  redirectUri: window.location.origin + callbackPath,
  scopes: ['openid', 'profile', 'email', 'groups'],
  authParams: {
    issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  },
};

export { oktaAuthConfig, oktaSignInConfig };
