import React, { useEffect } from 'react';
import { AuthState } from '@okta/okta-auth-js';

import Teasers from './Teasers.card';
import { Base_url, fetchOptions } from 'components/utils/utils';
import useFetch from 'components/utils/hooks/useFetch';
import { useModals } from '@mantine/modals';
import modalGif from '../modals/assets/data_not_found.gif';

type Props = {
  auth: AuthState;
  site: string;
  dispatchTarget:
    | 'SET_ARTICLE_ID_VG'
    | 'SET_ARTICLE_ID_E24'
    | 'SET_ARTICLE_ID_STORBY'
    | 'SET_ARTICLE_ID_AFFILIATE';
};

const Spots = ({ auth, site, dispatchTarget }: Props) => {
  let options = fetchOptions(auth);
  const modals = useModals();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isLoading, data } = useFetch(
    `${Base_url}/articles/live/2/${site}`,
    options,
  );

  const { isLoading: isLoadingBandits, data: bandits } = useFetch(
    `${Base_url}/bandits/all/keys`,
    options,
  );

  const liveIDs: Array<string> = data?.live_ids;

  const openContextModal = () =>
    modals.openContextModal('baseModal', {
      title: <h4>Oh no, something went wrong!</h4>,
      centered: true,
      innerProps: {
        modalBody:
          "We couldn't find any data or connect to the server. \n\nPlease contact the development team.",
        altImg: 'No data found gif',
        imgSource: modalGif,
      },
    });

  useEffect(() => {
    let loading = true;
    if (liveIDs?.length === 0 && loading) {
      openContextModal();
    }

    return function cleanup() {
      loading = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, liveIDs?.length]);

  return (
    <>
      {!isLoading && !isLoadingBandits
        ? liveIDs?.map((id: string) => {
            return (
              <Teasers
                key={id}
                site={site}
                articleId={id}
                auth={auth}
                dispatchTarget={dispatchTarget}
                isChecked={bandits.includes(`${site}_${id}`)}
              />
            );
          })
        : null}
    </>
  );
};

export default Spots;
