import React, { useEffect } from 'react';
import { useAppSelector } from 'components/utils/hooks/useRedux';
import usePersistedState from 'components/utils/hooks/usePersistedState';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Dropdown from 'components/Dropdown';
import Autocomplete from '../statistics/autoComplete/Autocomplete';
import { useOktaAuth } from '@okta/okta-react';
import TeaserTable from './TeaserTable';

import './Search.scss';

const Search = () => {
  const [isClosed, toggle] = usePersistedState('Sidemenu', false);
  const getStatisticsValue = useAppSelector((state) => state.dropDownReducer);
  const getStatisticsData = useAppSelector((state) => state.statsDataReducer);

  const { authState } = useOktaAuth();

  const newsrooms = [
    { name: 'VG', value: 'vg', default: true },
    { name: 'E24', value: 'e24', default: false },
    { name: 'Storby', value: 'storby', default: false },
    { name: 'Affiliate', value: 'affiliate', default: false },
  ];

  useEffect(() => {
    if (window.innerWidth < 1920) {
      toggle(true);
    }
  }, [toggle]);

  // Sets the starting url query as ?site=vg, when components load.
  useEffect(() => {
    const url: URL = new URL(window.location.toString());
    url.searchParams.set('site', getStatisticsValue.dropdownStatistics);
    window.history.pushState(null, '', url.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authState) return null;

  const statsData: any = getStatisticsData.statsData;
  const articleID: string = getStatisticsData.searchString;
  const newsroom: string = getStatisticsValue.dropdownStatistics;

  return (
    <div
      className="parent"
      style={isClosed ? { gridTemplateColumns: '5rem' } : {}}
    >
      <div className="sideBar_container">
        <Sidebar
          toggle={isClosed}
          handleClick={() => {
            toggle(!isClosed);
          }}
        />
      </div>
      <div className="dashboard_container">
        <Header />
        <div className="pg_wrapper">
          <div className="toolbar">
            <Dropdown
              dispatchTarget={'SET_DROPDOWN_STATISTICS'}
              options={newsrooms}
              startValue="vg"
            />
            <Autocomplete
              auth={authState}
              site={getStatisticsValue.dropdownStatistics}
            />
          </div>
          <div className="search">
            <TeaserTable
              data={statsData?.teasers}
              articleID={articleID}
              newsroom={newsroom}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
