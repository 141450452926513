import { Sx } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { ReactNode } from 'react';

export const notification = (
  data: any,
  onClose?: () => void,
  onOpen?: () => void,
  icon?: ReactNode,
  style?: React.CSSProperties,
  sx?: Sx | (Sx | undefined)[] | undefined,
): void => {
  showNotification({
    id: data.id,
    title: data.title,
    message: data.message,
    disallowClose: data.disallowClose,
    autoClose: data.autoClose || data.closeTime,
    color: data.color,
    loading: data.loading,
    style: style,
    sx: sx,
    onClose: onClose,
    onOpen: onOpen,
    icon: icon,
  });
};
