import React, { useState } from 'react';
import { calcDomainMax, desimalToPercent } from '../utils';
import VerticalChart from './VerticalChart';

import variables from 'global.module.scss';
import Card from 'components/Card';
import { Group, Menu, Radio, RadioGroup } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

type Props = {
  data_F?: any;
  data_M?: any;
};

const SegmentChart = ({ data_F, data_M }: Props) => {
  const [value, setValue] = useState<string>('CTR');
  const [opened, handlers] = useDisclosure(false);

  return (
    <Card height="350px">
      <Group position="apart">
        <h2 style={{ marginTop: '-2rem' }}>Age segment male - {value}</h2>
        <Menu opened={opened} onOpen={handlers.open} onClose={handlers.close}>
          <Menu.Label>Segment filter</Menu.Label>
          <Group align="flex-end" spacing="xs" p={2} ml={8} mb={2}>
            <RadioGroup
              value={value}
              size="sm"
              onChange={setValue}
              orientation="vertical"
              required
            >
              <Radio value="CTR" label="CTR" />
              <Radio value="impressions" label="Impressions" />
              <Radio value="clicks" label="Clicks" />
            </RadioGroup>
          </Group>
        </Menu>
      </Group>
      {Object.keys(data_M).length > 0 ? (
        <VerticalChart
          fill={variables.stats_blue}
          data={data_M}
          yKey="age_segment"
          barKey={value}
          xFormate={desimalToPercent}
          dataMax={calcDomainMax(value, data_M, data_F)}
        />
      ) : (
        <p className="no-content">No content to load</p>
      )}
      <h2 style={{ marginTop: '-1rem', marginBottom: '1rem' }}>
        Age segment female - {value}
      </h2>
      {Object.keys(data_F).length > 0 ? (
        <VerticalChart
          fill={variables.stats_green}
          data={data_F}
          yKey="age_segment"
          barKey={value}
          xFormate={desimalToPercent}
          dataMax={calcDomainMax(value, data_M, data_F)}
        />
      ) : (
        <p className="no-content">No content to load</p>
      )}
    </Card>
  );
};

export default SegmentChart;
