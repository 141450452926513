import React from 'react';
import Card from 'components/Card';
import { desimalToPercent } from '../utils';
import { limitString } from 'components/utils/utils';

import './styles/Tooltip.scss';

const Tooltip = ({ active, payload, label }: any) => {
  if (active) {
    let id: string = payload[0]?.payload.frontId;
    return (
      <Card height="65px">
        <h3 className="tooltip_header">{`${limitString(
          id.toString(),
          25,
        )}`}</h3>
        <p
          className={/[^\d-]/g.test(label) ? 'hide_label' : 'label'}
        >{`Age: ${label}`}</p>
        <p className="label">{`Gender: ${payload[0]?.payload.gender_segment}`}</p>
        <p className="label">{`${payload[0]?.dataKey} : ${desimalToPercent(
          payload[0]?.value,
        )}`}</p>
      </Card>
    );
  }

  return null;
};

export default Tooltip;
