import { createReducer } from '@reduxjs/toolkit';
import { setDevMessage } from 'actions/devMessageAction';

const initState = {
  dev_message: {
    id: 'a5adecea-0985-43ca-917f-fe17e38b6f53',
    type: 'development',
    title: '',
    message: 'No logs exists',
  },
};

const devMessageReducer = createReducer(initState, (builder) => {
  builder
    .addCase(setDevMessage, (state, action) => {
      state.dev_message = action.payload;
    })
    .addDefaultCase((state, action) => state);
});

export default devMessageReducer;
