import React from 'react';
import { Up, Down, Unfold } from 'components/assets/icons/component_icons/sort';
import { Group, UnstyledButton, Text, Center } from '@mantine/core';
import variables from 'global.module.scss';
import { useStyles } from './useStyles';

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  allowSort: boolean;
}

const Th = ({
  children,
  reversed,
  sorted,
  onSort,
  allowSort = false,
}: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? Up : Down) : Unfold;

  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          {allowSort ? (
            <Center className={classes.icon}>
              <Icon
                size={14}
                viewBox="0 0 48 48"
                color={variables.prime_dark}
              />
            </Center>
          ) : null}
        </Group>
      </UnstyledButton>
    </th>
  );
};

export default Th;
