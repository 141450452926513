export const sortData = (
  data: any,
  payload: { sortBy: keyof string; reversed: boolean },
) => {
  return [...data].sort((a, b) => {
    if (payload.reversed) {
      return b[payload.sortBy] - a[payload.sortBy];
    }
    return a[payload.sortBy] - b[payload.sortBy];
  });
};
