import React, { useState } from 'react';
import {
  Progress,
  Box,
  Text,
  Group,
  SimpleGrid,
  RadioGroup,
  Radio,
  Menu,
} from '@mantine/core';
import { numberFormate, desimalToPercent } from '../utils';
import { useStyles } from './segments.useStyles';

import './Segments.scss';
import { useDisclosure } from '@mantine/hooks';

type StatsSegmentsProps = {
  byline?: string;
  colors: Array<string>;
  data: {
    gender_segment: string;
    count: number;
    clicks: number;
    CTR: number;
  }[];
};

const StatsSegments = ({ data, byline, colors }: StatsSegmentsProps) => {
  const { classes } = useStyles();
  const [value, setValue] = useState<string>('impressions');
  const [opened, handlers] = useDisclosure(false);

  // Calculate the given total based of the data object key
  const calcTotal = (value: string): number => {
    return data.reduce((prev: number, cur: any) => {
      return prev + cur[value];
    }, 0);
  };

  // Calculate the part of the segment (in %)
  const calcPart = (stat: any, key: string): string => {
    return ((stat[value] / calcTotal(value)) * 100).toFixed(0);
  };

  // Returns the spesific data format for the Progress
  const segments: any = data.map((segment: any, index: number): any => {
    let part: number = parseInt(
      ((segment[value] / calcTotal(value)) * 100).toFixed(0),
    );
    return {
      value: part,
      color: colors[index],
      label: part > 10 ? `${part}%` : null,
    };
  });

  const getStatCount = (stat: any): string | undefined => {
    if (value === 'CTR') {
      return desimalToPercent(stat[value]);
    }
    return numberFormate(stat[value]);
  };

  const getTotal = (): string | undefined => {
    const total: number = calcTotal(value);
    if (value === 'CTR') {
      const totalCTR: number = calcTotal('clicks') / calcTotal('impressions');
      return desimalToPercent(totalCTR);
    }
    return numberFormate(total);
  };

  const descriptions = data.map((stat: any, index: number) => (
    <Box
      key={stat.gender_segment}
      sx={{ borderBottomColor: colors[index] }}
      className={classes.stat}
    >
      <h4 id="StatLabel">{stat.gender_segment.toUpperCase()}</h4>

      <Group position="apart" align="flex-end" spacing={0}>
        <h3 id="statCount">{getStatCount(stat)}</h3>
        <Text
          color={colors[index]}
          weight={700}
          size="xs"
          className={classes.statCount}
        >
          {calcPart(stat, value)}%
        </Text>
      </Group>
    </Box>
  ));

  return (
    <>
      <Group position="apart">
        <h1 className="page_title">Gender segment</h1>
        <Menu opened={opened} onOpen={handlers.open} onClose={handlers.close}>
          <Menu.Label>Segment filter</Menu.Label>
          <Group align="flex-end" spacing="xs" p={2} ml={8} mb={2}>
            <RadioGroup
              value={value}
              size="sm"
              onChange={setValue}
              orientation="vertical"
              required
            >
              <Radio value="impressions" label="Impressions" />
              <Radio value="clicks" label="Clicks" />
              <Radio value="CTR" label="CTR" />
            </RadioGroup>
          </Group>
        </Menu>
      </Group>
      <p id="statTotal">{`Total: ${getTotal()} ${value}`}</p>
      <Progress
        sections={segments}
        size={34}
        classNames={{ label: classes.progressLabel }}
        mt={40}
      />
      <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="xl">
        {descriptions}
      </SimpleGrid>
    </>
  );
};

export default StatsSegments;
