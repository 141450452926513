import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { HistoryObject } from './Types';
import CustomTooltip from './Tooltip.custom';

type Props = {
  data: Array<HistoryObject>;
  title?: string;
  width?: string | number;
  height?: string | number;
  fill: string;
  xKey?: string;
  dataKey: string;
  xFormate?: any;
  yFormate?: any;
};

const Linechart = ({
  data,
  width = '100%',
  height = 245,
  fill,
  xKey = 'id',
  dataKey,
  xFormate,
  yFormate,
}: Props) => {
  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={data}
          margin={{
            top: 30,
            right: 50,
            left: 10,
            bottom: 50,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={xKey}
            axisLine={false}
            tickLine={false}
            tickMargin={10}
            tickCount={data?.length}
            tickFormatter={(key) => xFormate(key)}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={(key) => yFormate(key)}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            legendType="none"
            type="basic"
            dataKey={dataKey}
            stroke={fill as string}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default Linechart;
