import { createReducer } from '@reduxjs/toolkit';
import {
  setStatisticsValue,
  setStorbyValue,
  setVgTeaserId,
  setStorbyTeaserId,
  setE24TeaserId,
  resetValueToDefault,
  setAffiliateTeaserId,
} from './../actions/dropdownAction';

const initState = {
  dropdownStorby: 'all',
  dropdownStatistics: 'vg',
  dropdownVgTeaserId: 'all',
  dropdownStorbyTeaserId: 'all',
  dropdownE24TeaserId: 'all',
  dropdownAffiliateTeaserId: 'all',
};

const dropDownReducer = createReducer(initState, (builder) => {
  builder
    .addCase(setStorbyValue, (state, action) => {
      state.dropdownStorby = action.payload;
    })
    .addCase(setStatisticsValue, (state, action) => {
      state.dropdownStatistics = action.payload;
    })
    .addCase(setVgTeaserId, (state, action) => {
      state.dropdownVgTeaserId = action.payload;
    })
    .addCase(setStorbyTeaserId, (state, action) => {
      state.dropdownStorbyTeaserId = action.payload;
    })
    .addCase(setE24TeaserId, (state, action) => {
      state.dropdownE24TeaserId = action.payload;
    })
    .addCase(setAffiliateTeaserId, (state, action) => {
      state.dropdownAffiliateTeaserId = action.payload;
    })
    .addCase(resetValueToDefault, (state, action) => {
      state.dropdownStorby = initState.dropdownStorby;
      state.dropdownStatistics = initState.dropdownStatistics;
      state.dropdownVgTeaserId = initState.dropdownVgTeaserId;
      state.dropdownStorbyTeaserId = initState.dropdownStorbyTeaserId;
      state.dropdownAffiliateTeaserId = initState.dropdownAffiliateTeaserId;
    })
    .addDefaultCase((state, action) => state);
});

export default dropDownReducer;
